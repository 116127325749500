import { gql } from '@apollo/client';

export const LightApiKeyFragment = gql`
  fragment LightApiKey on LightApiKey {
    creationDate
    revocationDate
    name
    accessRights {
      label
    }
    state
    uuid
  }
`;
