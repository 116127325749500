import { Typography, Tooltip, Box } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime
} from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import ApiKeyCardAction from './ApiKeyCardAction';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { ILightApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { useStyles } from './ApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import { compareDates } from 'Tools/compareDates';
import testIds from 'Tests/TestIds';
import CardWithAvatar from 'Components/Shared/CardWithAvatar/CardWithAvatar';
import { useAvatarColor } from 'Hooks/useAvatarColor';

interface Props {
  api: ILightApiKey;
  refetchQueries: any;
}

function ApiKeyCard(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate();
  const { api, refetchQueries } = props;

  const apiColor = useAvatarColor({ identifier: api.uuid });

  return (
    <CardWithAvatar
      testId={'apikey-card-'+api.uuid}
      bgColor={apiColor}
      avatar={<VpnKeyIcon sx={{ color: 'white' }} />}
      cardHeader={ 
        api.creationDate !== null && api.state === ApiKeyState.Active ? (
          <Tooltip
            title={
              <>
                <FormattedDate value={api.creationDate} /> -{' '}
                <FormattedTime value={api.creationDate} />
              </>
            }
          >
            <Typography
              variant="caption"
              classes={{ root: classes.subtitle1 }}
              data-testid={testIds.component.apikeycard.creationDate + api.uuid}
            >
              {compareDates(new Date(api.creationDate), new Date()) ? (
                ts('common.dates.thisInstant')
              ) : (
                <FormattedRelativeTime
                  {...selectUnit(new Date(api.creationDate))}
                />
              )}
            </Typography>
          </Tooltip>
        ) : (
          <div title={ts('page.apiKeys.undefinedCreationDate')} />
        )
      }
      cardContent={
        <>
          <Typography variant="subtitle1" classes={{ root: classes.subtitle1 }}>
            {api.name}
          </Typography>
          <Box height="8px" />
          <Typography variant="body2" className={classes.body2}>
            {/* For now one key is attached to one service. In future it will be possible to assign an apikey to many services */}
            {api.accessRights?.[0]?.label ?? ''}
          </Typography>
        </>
      }
      cardFooter={
        <ApiKeyCardAction
          key={api.uuid}
          api={api}
          refetchQueries={refetchQueries}
        />
      }
    />
  );
}

export default ApiKeyCard;
