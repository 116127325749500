import { getIn, useFormikContext } from 'formik';

import ErrorMessage from './ErrorMessage';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';

export type TOption = {
  value: string;
  label: string;
};
interface Props extends Omit<SelectProps, 'name'> {
  name: string;
  options: TOption[];
  size?: 'small' | 'medium';
}

const Selector = ({
  name,
  options,
  label,
  size,
  ...rest
}: Props) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();

  const value = getIn(values, name);
  const error = getIn(errors, name);
  const visible = getIn(touched, name);

  return (
    <Grid container item direction="column">
      <FormControl variant="outlined" size={size || 'small'}>
        <InputLabel id="simple-selector" variant="outlined">
          {label}
        </InputLabel>
        <Select
          labelId="simple-selector"
          name={name}
          value={value}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
          label={label}
          onChange={(e: any) => setFieldValue(name, e.target.value as string)}
          {...rest}
        >
          {options.map(option => (
            <MenuItem key={option.value + option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && <ErrorMessage error={error} visible={visible} />}
    </Grid>
  );
};

export default Selector;
