import { generatePath, Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import Skeleton from 'Theme/components/Skeleton';
import Container from 'Theme/components/Container';
import { PrivateRoutes } from 'Router/Routes';
import TestIds from 'Tests/TestIds';
import Onboarding from '../../Components/Onboarding';
import { PersonContext } from 'Context/CurrentPerson';
import useTranslate from 'Hooks/useTranslate';
import { useContext, useMemo, useState } from 'react';
import { useStyles } from './AccountSelection.styles';
import { Helmet } from 'react-helmet';
import { LocalContext } from 'Context/Local.context';
import SearchByAccount from 'Components/Shared/SearchBars/SearchByAccount/SearchByAccount';
import { debounce } from 'Tools/debounce';
import { DEBOUNCE_DELAY } from 'Theme/config';

interface Props {
  accounts: IAccount[];
  onSearch: (value: string) => void;
}

const AccountSelection = ({ accounts, onSearch }: Props) => {
  const { currentPersonRoles } = useContext(PersonContext);
  const { accountsCount } = useContext(LocalContext);

  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [filterValue, setFilterValue] = useState<null | string>(null);
  const ts = useTranslate();
  const classes = useStyles();

  const debouncedSendRequest = useMemo(() => {
    return debounce(onSearch, DEBOUNCE_DELAY);
  }, [onSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setFilterValue(currentValue);
    debouncedSendRequest(currentValue);
  };
  const handleClear = () => {
    setFilterValue(null);
    setSelectedAccount(null);
  };

  return (
    <>
      <Helmet title={ts('page.accountSelection.title')} />
      <Container>
        <Grid container className={classes.root}>
          <Grid item sm={6}>
            <Typography
              variant="h4"
              color="textSecondary"
              classes={{ root: classes.heading }}
            >
              {ts('page.accountSelection.whichAccount', {
                b: (children: React.ReactNode) => (
                  <strong>{children}</strong>
                )
              })}
            </Typography>
            <SearchByAccount
              name="account"
              accounts={accounts}
              selectedAccount={selectedAccount}
              filterValue={filterValue ?? ''}
              handleSearch={handleSearch}
              handleClear={handleClear}
              handleChooseAccount={(acc: IAccount) => setSelectedAccount(acc)}
              inputLabel={ts('page.accountSelection.accountLabel')}
            />
            <div className={classes.selectionButtons}>
              <Button
                disabled={!selectedAccount}
                className={classes.enterButton}
                component={Link}
                data-testid={TestIds.pages.accountSelection.profileLink}
                to={generatePath(PrivateRoutes.dashboard.path, {
                  accountCode: selectedAccount ? selectedAccount.code : false
                })}
                variant="contained"
                color="primary"
              >
                {ts('page.accountSelection.loginToAccount')}
              </Button>
              <Link
                to={generatePath(PrivateRoutes.dashboard.path, {
                  accountCode: 'all'
                })}
                className={classes.linkTo}
              >
                <Typography variant="caption">
                  {ts('component.administratedAccountsSelector.seeAll')}
                </Typography>
              </Link>
            </div>
          </Grid>
          <Grid container direction="column" alignItems="center" item sm={6}>
            {accountsCount === undefined ? (
              <>
                <Skeleton width="150px" height="60px" />
                <Skeleton width="180px" />
              </>
            ) : (
              <>
                {currentPersonRoles.hasAccessToAccounts && <Onboarding />}
                <Typography variant="h1" classes={{ root: classes.counter }}>
                  {accountsCount}
                </Typography>
                <Typography variant="body1">
                  {ts('page.accountSelection.accountsFound', {
                    count: accountsCount
                  })}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AccountSelection;
