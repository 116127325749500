import Skeleton from 'Theme/components/Skeleton';
import Container from 'Theme/components/Container';
import NoApiKey from '../NoApiKey';
import ApiKeyCard from '../ApiKeyCard';
import { useStyles } from '../ApiKey.styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ILightApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { GetApiKeys } from 'Apollo/queries/apikey/GetApiKeys';
import { LocalContext } from 'Context/Local.context';
import { useContext } from 'react';
import { APIKEY_CARD_PER_PAGE_DEFAULT } from 'Theme/config';

interface Props {
  hasNextPage: boolean;
  loading: boolean;
  apiKeys: ILightApiKey[];
  totalCount: number;
  fetchMoreData(): void;
  apiStatus: ApiKeyState;
}

function ApiStateTab({
  hasNextPage,
  apiKeys,
  loading,
  totalCount,
  apiStatus,
  fetchMoreData
}: Readonly<Props>) {
  const classes = useStyles();

  const { currentAccount } = useContext(LocalContext);

  return (
    <div style={{ top: '40px', position: 'relative' }} data-testid="apikey-list">
      <Container>
        {loading && (
          <div className={classes.cards} aria-label='loading'>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton
                variant="rectangular"
                width="200px"
                height="200px"
                classes={{ root: classes.card }}
                key={index as any}
              />
            ))}
          </div>
        )}
        {!loading && totalCount === 0 && <NoApiKey />}

        {!loading && totalCount > 0 && !!apiKeys && (
          <InfiniteScroll
            dataLength={apiKeys.length}
            next={fetchMoreData}
            hasMore={hasNextPage}
            loader={
              <div className={classes.cards}>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton
                    variant="rectangular"
                    width="200px"
                    height="200px"
                    classes={{ root: classes.card }}
                    key={index as any}
                  />
                ))}
              </div>
            }
            className={classes.cards}
          >
            {apiKeys.map((obj: ILightApiKey, i: number) => {
              return <ApiKeyCard key={i as any} api={obj} refetchQueries={[
                {
                  query: GetApiKeys,
                  variables: {
                    accountFilter: currentAccount?.code ?? null,
                    page: { first: APIKEY_CARD_PER_PAGE_DEFAULT, offset: 0 },
                    serviceFilter: null,
                    state: apiStatus
                  },
                  fetchPolicy: 'cache-first'
                },
                'GetApiKeys'
              ]} />
            })}
          </InfiniteScroll>
        )}
        {loading && (
          <div className={classes.cards}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                variant="rectangular"
                width="200px"
                height="200px"
                classes={{ root: classes.card }}
                key={index as any}
              />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}

export default ApiStateTab;
