import { useFormikContext, getIn } from 'formik';
import { TextField, TextFieldProps, Grid } from '@mui/material';

interface ITextInput extends Omit<TextFieldProps, 'name'> {
  name: string;
}

const TextInput = ({ name, helperText, ...otherProps }: ITextInput) => {
  const {
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched
  } = useFormikContext();
  const value = getIn(values, name);
  const error = getIn(errors, name);
  const visible = getIn(touched, name);

  return (
    <Grid container direction="column">
      <TextField
        aria-label={`input-${name}`}
        size="small"
        variant="outlined"
        onBlur={() => setFieldTouched(name)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue(name, e.target.value)
        }
        value={value}
        error={!!error && visible}
        {...otherProps}
        helperText={(visible && error) || helperText}
      />
    </Grid>
  );
};

export default TextInput;
