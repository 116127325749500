import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import {
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider
} from '@mui/material';
import TestIds from 'Tests/TestIds';
import { PersonAvatar, PaperTitle } from 'Theme/components';
import Skeleton from 'Theme/components/Skeleton';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import { userFullName } from 'Tools';
import { useState } from 'react';
import { GetFellowAdministrators } from 'Apollo/queries/person/GetFellowAdministrators';
import colors from 'Theme/constants/colors';

interface Props {
  person: IPersonWithAccount;
}

const Administrators = (props: Props) => {
  const { person } = props;

  const rowsPerPage = 5;

  const [currentPage, setCurrentPage] = useState<number>(0);

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => setCurrentPage(page);

  return (
    <Query
      query={GetFellowAdministrators}
      variables={{
        personIdentifier: person.immutableId,
        accountCode: person.account?.code,
        first: rowsPerPage,
        offset: rowsPerPage * currentPage
      }}
    >
      {({ loading, data }: QueryResult<GetFellowAdministratorsData>) => {
        const { totalCount = -1 } = data?.fellowAdministrators || {};
        const skeletonArray = Array.from({ length: rowsPerPage });

        return (
          <Paper style={{ marginBottom: '2em' }}>
            <PaperTitle
              title={
                <FormattedMessage id="page.userProfile.section.administrators.title" />
              }
            />

            {loading ? (
              <List>
                {skeletonArray.map((_: any, index: number) => (
                  <React.Fragment key={'skeleton'+index as any}>
                    <ListItem alignItems="center" key={'listItem'+index as any}>
                      <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Skeleton
                            component="span"
                            variant="rectangular"
                            width="20%"
                            style={{
                              marginBottom: '8px'
                            }}
                          />
                        }
                        secondary={
                          <Skeleton
                            component="span"
                            variant="rectangular"
                            width="10%"
                          />
                        }
                      />
                    </ListItem>
                    {index === skeletonArray.length - 1 ? (
                      <Divider variant="fullWidth" />
                    ) : (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <List>
                {data?.fellowAdministrators.edges.map(
                  (administrator: { node: IPerson }, index: number) => {
                    const {
                      node,
                      node: { firstName, lastName, email }
                    } = administrator;

                    let mailtoLink = email ? `mailto:${email}` : '';

                    return (
                      <div key={'administrator-'+index}>
                        <ListItem
                          key={'admin'+ index}
                          alignItems="center"
                          data-testid={
                            TestIds.pages.userProfile.administrators.adminItem +
                            '-' +
                            index
                          }
                        >
                          <ListItemAvatar>
                            <PersonAvatar component="span" person={node} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={userFullName(firstName, lastName)}
                            secondary={email ? <a href={mailtoLink} style={{color: colors.blue[500]}}>{email}</a> : ""}
                            data-testid={
                              TestIds.pages.userProfile.administrators
                                .identity +
                              '-' +
                              index
                            }
                          />
                        </ListItem>
                        {index ===
                        data?.fellowAdministrators.edges.length - 1 ? (
                          <Divider variant="fullWidth" />
                        ) : (
                          <Divider variant="inset" />
                        )}
                      </div>
                    );
                  }
                )}
              </List>
            )}

            <TablePagination
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={null}
              rowsPerPageOptions={[]}
              page={currentPage}
              onPageChange={onChangePage}
              labelDisplayedRows={({
                from,
                to,
                count
              }: LabelDisplayedRowsArgs) => (
                <FormattedMessage
                  id="common.pagination.displayedRows"
                  values={{ from, to, count }}
                />
              )}
            />
          </Paper>
        );
      }}
    </Query>
  );
};

export default Administrators;
