export interface IConfig {
  isMobile: boolean;
  hasAccessToAccounts: boolean;
}

const useSpecificAccessRightsPosition = (config: IConfig) => {

  const calcTopPosition = () => {
    if (config.isMobile) {
      return config.hasAccessToAccounts ? '104px' : '57px';
    } else {
      return config.hasAccessToAccounts ? '112px' : '60px';
    }
  };

  const calcResultPosition = () => {
    if (config.isMobile) {
      return config.hasAccessToAccounts ? '40px' : '3em';
    } else {
      return config.hasAccessToAccounts ? '200px' : '168px';
    }
  };

  return {
    headerPosition: calcTopPosition(),
    contentPosition: calcResultPosition()
  }
};

export default useSpecificAccessRightsPosition;
