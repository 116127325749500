import { Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CardWithAvatar from 'Components/Shared/CardWithAvatar/CardWithAvatar';
import Container from 'Theme/components/Container';
import Skeleton from 'Theme/components/Skeleton';
import EmptyState from 'Components/Shared/EmptyState/EmptyState';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Link, generatePath, useParams } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import Helmet from 'react-helmet';
import { CdsColorBackgroundPrimaryMain } from '@cegid/design-tokens';

interface Props {
  loading: boolean;
  dataResult: IOrganization[];
}

function Organizations({ loading, dataResult }: Readonly<Props>) {
  const ts = useTranslate();
  const { accountCode } = useParams<{ accountCode: string }>();

  return (
    <Container>
      <Helmet title={ts('page.organizations.title')} />
      <Grid container>
        {loading && (
          <div style={{ display: 'flex' }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                variant="rectangular"
                width="200px"
                height="200px"
                key={index as any}
                style={{ marginRight: '1em' }}
              />
            ))}
          </div>
        )}
        {!loading && dataResult?.length === 0 && (
          <Paper sx={{ width: '100%', marginTop: '24px' }}>
            <EmptyState
              primary={ts('page.organizations.noOrganizationFound')}
              icon={<CheckCircleOutlineIcon />}
            />
          </Paper>
        )}
        {!loading &&
          dataResult?.length !== 0 &&
          dataResult.map(row => {
            return (
              <Grid item key={row.code}>
                <CardWithAvatar
                  bgColor={CdsColorBackgroundPrimaryMain}
                  testId={`organization-${row.code}`}
                  role="organizationCard"
                  avatar={<ApartmentIcon style={{ color: 'white' }} />}
                  cardHeader={
                    <Tooltip
                      title={ts('common.dates.creationDate', {
                        value: new Date(row.dateCreation).toLocaleDateString()
                      })}
                      placement="bottom"
                    >
                      <InfoOutlined fontSize="small" sx={{ mb: 1 }} />
                    </Tooltip>
                  }
                  cardContent={
                    <Tooltip title={row.name} placement="bottom">
                      <Typography
                        variant="subtitle1"
                        style={{
                          opacity: 0.87,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxHeight: '56px'
                        }}
                      >
                        {row.name}
                      </Typography>
                    </Tooltip>
                  }
                  cardFooter={
                    <>
                      <div />
                      <Grid item>
                        <Button
                          color="primary"
                          endIcon={<ArrowForward />}
                          component={Link}
                          to={generatePath(
                            PrivateRoutes.organizationProfile.path,
                            {
                              accountCode: accountCode,
                              organizationCode: row.code
                            }
                          )}
                        >
                          {ts('common.action.see.seeMore')}
                        </Button>
                      </Grid>
                    </>
                  }
                />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}

export default Organizations;
