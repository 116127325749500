import React, { useState } from 'react';
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import ApiKeyRevokeConfirmation from './ApiKeyRevokeConfirmation';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { Link, useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HistoryIcon from '@mui/icons-material/History';
import TestIds from 'Tests/TestIds';
import { useStyles } from './ApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import { InfoOutlined } from '@mui/icons-material';
import { ILightApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';

interface Props {
  api: ILightApiKey;
  key: number | string;
  refetchQueries: any;
}

function ApiKeyCardAction(props: Readonly<Props>) {
  const { api, refetchQueries } = props;

  const ts = useTranslate();

  const { accountCode } = useParams<IUriParams>();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
      setIsOpen(!isOpen);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton
        className={classes.moreButton}
        onClick={handleClick}
        data-testid={TestIds.pages.apiKeys.card.moreActionButton}
        size="large">
        <MoreHorizIcon />
      </IconButton>
      <Menu
        data-testid={TestIds.pages.apiKeys.card.apiCardActionsMenu}
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.apiActionMenuItem}
          component={Link as any}
          to={`/${accountCode}/apikey/${api.uuid}`}
        >
          <ListItemText>{ts('page.apiKeys.details')}</ListItemText>
          <Typography variant="body1">
            <InfoOutlined fontSize="medium" color="inherit" />
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.apiActionMenuItem}
          component={Link as any}
          to={`/${accountCode}/apikey/${api.uuid}/audit`}
          data-testid={TestIds.pages.apiKeys.card.historyIconButton}
        >
          <ListItemText>{ts('page.apiKeys.history')}</ListItemText>
          <Typography variant="body1">
            <HistoryIcon color="inherit" fontSize="medium"/>
          </Typography>
        </MenuItem>
        {api.state === ApiKeyState.Active && (
          <ApiKeyRevokeConfirmation
            onClose={handleClose}
            api={api}
            refetchQueries={refetchQueries}
          />
        )}
      </Menu>
    </div>
  );
}

export default ApiKeyCardAction;
