import { useQuery } from '@apollo/client';
import { GetApiKeys } from 'Apollo/queries/apikey/GetApiKeys';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import useNotification from 'Hooks/useNotification';
import { APIKEY_CARD_PER_PAGE_DEFAULT } from 'Theme/config';
import ApiStateTab from './ApiStateTab';
import { IEdgeOfILightApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { useState } from 'react';

interface Props {
  accountCode: string;
  apiStatus: ApiKeyState;
}

const ApiStateTabContainer = ({ accountCode, apiStatus }: Readonly<Props>) => {
  const { onError } = useNotification();
  const [pageOffset, setPageOffset] = useState<number>(0);


  const initialVariables = {
    accountFilter: accountCode !== 'all' ? accountCode : '',
    page: { first: APIKEY_CARD_PER_PAGE_DEFAULT, offset: 0 },
    serviceFilter: null,
    state: apiStatus
  };

  const { data, loading: loadingQuery, fetchMore } = useQuery<GetApiKeysData>(
    GetApiKeys,
    {
      variables: { ...initialVariables },
      onError,
      fetchPolicy: 'cache-and-network'
    }
  );

  const apiKeys = data?.apiKeys.edges.map((el: IEdgeOfILightApiKey) => el.node) ?? [];
  const totalCount = data?.apiKeys.totalCount ?? 0;

  const hasNextPage = data?.apiKeys.pageInfo.hasNextPage ?? false;

  const fetchMoreData = () => {
    const newOffset = pageOffset + APIKEY_CARD_PER_PAGE_DEFAULT;
    fetchMore({
      variables: {
        page: {
          offset: newOffset,
          first: APIKEY_CARD_PER_PAGE_DEFAULT
        }
      },
      updateQuery: (
        prev: GetApiKeysData,
        { fetchMoreResult }: { fetchMoreResult: GetApiKeysData }
      ) => {
        if (fetchMoreResult == null) {
          return prev;
        }

        return {
          ...prev,
          apiKeys: {
            ...prev.apiKeys,
            edges: prev.apiKeys.edges.concat(fetchMoreResult.apiKeys.edges),
            pageInfo: fetchMoreResult.apiKeys.pageInfo
          }
        };
      }
    }  
  );
    setPageOffset(newOffset);
  };

   return (
    <ApiStateTab
      hasNextPage={hasNextPage}
      fetchMoreData={fetchMoreData}
      loading={loadingQuery}
      apiKeys={apiKeys}
      totalCount={totalCount}
      apiStatus={apiStatus}
    />
  );
};

export default ApiStateTabContainer;
