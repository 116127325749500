import { useQuery } from '@apollo/client';
import { ServiceSubscriptionOrder, GetServiceSubscriptions } from 'Apollo';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { calculateNewPage } from 'Tools/calculateNewPage';
import ServicesTable from '.';
import useMediaQuery from '@mui/material/useMediaQuery';
import mediaQueries from 'Theme/constants/mediaQueries';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

const ServicesTableContainer = () => {
  const { accountCode } = useParams<IUriParams>();
  const ErrorHandler = useContext(ErrorHandlerContext);
  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState<ServiceSubscriptionOrder>(
    ServiceSubscriptionOrder.NameAsc
  );

  const initialVariables = {
    page: { first: rowsPerPage, offset: currentPage * rowsPerPage },
    accountCode: accountCode === 'all' ? null : accountCode,
    apiKeyEligibleOnly: false,
    orderBy: orderBy
  };

  const { data, loading, error, refetch } = useQuery<
    GetServiceSubscriptionsData
  >(GetServiceSubscriptions, {
    onError: ErrorHandler.onError,
    variables: { ...initialVariables },
    fetchPolicy: 'cache-and-network'
  });

  const handleChangeServicesSearch = (value: string) => {
    const newFilter = value.trim();
    setCurrentPage(0);
    refetch({
      page: { first: rowsPerPage, offset: 0 },
      servicesFilter: newFilter
    });
  };

  const handleChangeOrderBy = (order: ServiceSubscriptionOrder) => {
    setOrderBy(order);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const Rows: IServiceSubscription[] =
    data?.serviceSubscriptions.edges.map(({ node }) => {
      return node;
    }) || [];

  return (
    <ServicesTable
      rows={Rows}
      error={error}
      totalCount={data?.serviceSubscriptions.totalCount ?? 0}
      loading={loading}
      isMobileP={isMobileP}
      currentPage={currentPage}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      onChangeOrderBy={handleChangeOrderBy}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      onChangeServicesSearch={handleChangeServicesSearch}
    />
  );
};

export default ServicesTableContainer;
