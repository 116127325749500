import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import ApiStateTabContainer from './ApiStateTab.container';

export default function TabActive(props: Readonly<{ accountCode: string }>) {
  return (
    <ApiStateTabContainer
      apiStatus={ApiKeyState.Active}
      accountCode={props.accountCode}
    />
  );
}
