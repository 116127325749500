import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { TProfileManagerMessagesKeys } from '../Languages/TProfileManagerMessages';
import useTranslate from './useTranslate';
import { ApolloError } from '@apollo/client';
import SvgIcon from '../Theme/components/SvgIcon/SvgIcon';
import { ErrorDomain } from 'Theme/components/ErrorHandler/types';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

interface INotifications {
  onError(e: ApolloError): void;
  onSuccess(
    tsId: TProfileManagerMessagesKeys,
    values?: Object,
    persist?: 'persist',
    iconPath?: string,
    testId?: string
  ): void;
  onFailure: (e: string, persist?: 'persist', iconPath?: string) => void;
  onHandle(errors: string[]): void;
}

interface IUseNotifications {
  domain?: ErrorDomain;
}

const useNotification = (props?: IUseNotifications): INotifications => {
  const { domain } = props ?? {};
  const ErrorHandler = useContext(ErrorHandlerContext);
  const ts = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const onHandle = (errors: string[]) => ErrorHandler.handle(errors, domain);
  const onError = (e: ApolloError) => ErrorHandler.onError(e, domain);

  const onFailure = (
    message: string,
    persist?: 'persist',
    iconPath?: string,
    testId?: string
  ) => {
    enqueueSnackbar(
      <Box style={{ display: 'flex' }}>
        {iconPath && (
          <Box component="figure" pr={2}>
            <SvgIcon path={iconPath} fill="#fff" />
          </Box>
        )}
        <Typography variant="body2" data-testid={testId}>{message}</Typography>
      </Box>,
      {
        variant: 'error',
        persist: persist === 'persist'
      }
    );
  };

  const onSuccess = (
    tsId: TProfileManagerMessagesKeys,
    values?: Object,
    persist?: 'persist',
    iconPath?: string,
    testId?: string
  ) => {
    enqueueSnackbar(
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {iconPath && (
          <Box component="div" pr={2} style={{ display: 'flex' }}>
            <SvgIcon path={iconPath} fill="#fff" />
          </Box>
        )}
        <Typography variant="body2" data-testid={testId}>
          {ts(tsId, { ...values })}
        </Typography>
      </Box>,
      {
        variant: 'success',
        persist: persist === 'persist'
      }
    );
  };

  return { onError, onSuccess, onFailure, onHandle };
};

export default useNotification;
