import flat from 'flat';
import {
  AuditAction,
  AuditActionResult,
  ActivityType,
  PersonAccessRightStatus
} from '../../Apollo';
import { ApiKeyEventType } from '../../Apollo/fragments/apikey/ApiKeyEvent';
import TProfileManagerMessages from '../TProfileManagerMessages';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

export default flat<TProfileManagerMessages, { [messageId: string]: string }>({
  applicationName: 'Gerenciamento de contas',
  common: {
    action: {
      loading: 'Carregando...',
      search: 'Pesquisar',
      subscribe: 'Subscrever',
      accept: 'Aceitar',
      confirm: 'Confirmar',
      deny: 'Recusar',
      add: 'Adicionar',
      delete: 'Eliminar',
      edit: 'Editar',
      back: 'voltar',
      export: 'Exportar',
      finish: 'Terminar',
      previous: 'Anterior',
      next: 'seguinte',
      cancel: 'Cancelar',
      reset: 'Reiniciar',
      toEnd: 'Terminar',
      agree: 'Concordar',
      retry: 'Repetir',
      understand: 'Entendi',
      moreDetails: 'Mais detalhes',
      validate: 'Validar',
      migrate: 'Migrar',
      open: 'Abrir',
      close: 'Fechar',
      ignore: 'Ignorar',
      clear: 'Limpar a pesquisa',
      yes: 'Sim',
      no: 'Não',
      ok: 'Ok',
      backToHomePage: 'Voltar à página inicial',
      activate: 'Ativar',
      deactivate: 'Desativar',
      doNotModify: 'Não modificar',
      seeProfile: 'Ver perfil',
      login: 'Entrar',
      copyCode: 'Copiar código',
      see: {
        seeMore: 'Ver mais',
        seeDescription: 'Ver a descrição',
        seeExpirationDate: 'Ver a data de expiração',
        seeGroupDetails: 'Ver os detalhes do grupo',
        seeTheList: 'Ver a lista'
      }
    },
    error: {
      disabledResult: 'Resultado indisponível',
    },
    date: {
      editExpirationDate: 'Editar a data de expiração',
      expireDetails: 'Expires on {date} - {hour}'
    },
    person: {
      role: {
        adm: 'Administrador',
        superAdm: 'Super administrador',
        superAdmSentence: 'Está a navegar com direitos de super administrador',
        superReaderSentence: 'Está a navegar com direitos de super leitor',
        admSuperReader: 'Administrador / Super leitor',
        reader: 'Leitor',
        superReader: 'Super leitor',
        user: 'Utilizador'
      }
    },
    service: {
      unavailable: 'Serviço indisponível',
      access: 'Acesso'
    },
    terminology: {
      accessRights: 'Direitos de acesso',
      alert: {
        info: 'Informação'
      },
      client: 'Conta cliente',
      status: 'Estado',
      active: 'Ativo',
      inactive: 'Inativo',
      account: 'Conta de cliente',
      accounts: 'Contas de clientes',
      description: 'Descrição',
      codeGroup: 'Código do grupo',
      famillies: 'famílias',
      groups: 'grupos',
      localAccount: 'Conta local',
      menu: 'Menu',
      MSaccount: 'Conta Microsoft',
      GoogleAccount: 'Conta Google',
      required: 'Obrigatório',
      services: 'serviços',
      tutorial: 'Tutorial',
      immutableId: 'Id imutável',
      email: 'Endereço de e-mail',
      or: 'ou',
      groupName: 'Nome do grupo',
      yes: 'Sí',
      no: 'No'
    },
    dates: {
      creationDate: 'Data de criação',
      today: 'Hoje',
      yesterday: 'Ontem',
      previously: 'Anteriormente',
      thisInstant: 'há 0 segundos',
      jours: 'dias'
    },
    label: {
      id: 'ID',
      page: 'Página',
      rowPerPage: 'Resultados / página',
      name: 'Nome'
    },
    pagination: {
      firstPage: 'Primeira página',
      lastPage: 'Última página',
      previousPage: 'Página anterior',
      nextPage: 'Página seguinte',
      displayedRows: '{from}-{to} de {count}'
    },
    xhrMaxItemCountReached:
      'Não pode selecionar mais de {XhrMaxItemCount} utilizadores atualmente.',
    welcome: 'Bem-vindo,',
    accountLabel: '{name} - {zipCode} {city} / {code}',
    switchAccount: 'Mudar de conta cliente',
    disabledAccount: '(Desativado)',
    tableHead: {
      name: 'Nome',
      status: 'Estado',
      active: 'Ativo',
      code: 'Código',
      validationCode: 'Código de validação',
      expiration: 'Expiração'
    }
  },
  component: {
    modals: {
      createUser: {
        heading: 'Convidar utilizadores',
        submit: 'Convidar',
        wizard: {
          heading: 'Gerencie os seus utilizadores',
          subheading:
            'Personalize e administre facilmente os dados, acessos e grupos dos seus utilizadores.'
        },
        labels: {
          emails: 'Endereços de e-mail',
          emailsHelperText:
            'Adicione vários utilizadores usando a vírgula. Máximo de 100 utilizadores.',
          accountCode: 'Código de conta de cliente'
        },
        invitationSent:
          'Enviou {invitationsQuantity, plural, zero {{invitationsQuantity, number} convite } one {{invitationsQuantity, number} convite } other {{invitationsQuantity, number} convites }}',
        invitationFailed: 'Os seguintes convites não puderam ser criados:'
      }
    },
    form: {
      placeholder: {
        emailsInput: 'Ex: nome@empresa.com, nome1@empresa.com'
      },
      label: {
        emailInputCount:
          'Total de endereços de e-mail a adicionar: {emailsCount}'
      }
    },
    accessrights: {
      pendingRequest: {
        count:
          '{value, plural, zero { {value} pedido } one { {value} pedido } other { {value} pedidos }}',
        sentence:
          '{value, plural, zero { {value} pedido } one { {value} pedido } other { {value} pedidos }} pendente(s)'
      }
    },
    administratedAccountsSelector: {
      allAccounts: 'Todas as contas de clientes',
      seeAll: 'Ver todas as contas de clientes'
    },
    searchBars: {
      availabledResult: {
        noResultPrimary: 'Nenhum resultado corresponde à sua pesquisa',
        noResultSecondary:
          'Encontre um utilizador pelo nome, apelido ou pelo e-mail.'
      },
      userSelectedSearchBar: {
        sentence:
          '{total, plural, one {{total} pedido corresponde à sua pesquisa} other {{total} pedidos correspondem à sua pesquisa}}'
      },
      placeholder: {
        users: 'Pesquisar utilizadores',
        services: 'Pesquisar serviços'
      }
    },
    table: {
      selectAll: 'Selecionar tudo ({count})',
      elementSelected: '{count} elemento(s) selecionado(s)'
    },
    tableServiceOwners: {
      remove: {
        confirmation: 'Está prestes a remover o proprietário do serviço {name}',
        success: 'O utilizador já não é proprietário do serviço'
      }
    },
    editToggle: {
      editGroup: {
        update: {
          success: 'O grupo foi atualizado com sucesso'
        }
      }
    },
    userAccountUpdate: {
      tooltip: 'Modificar a conta de cliente do utilizador',
      unlink: 'Desassociar da conta do cliente {value}',
      link: 'Associar a outra conta de cliente',
      unlinkDialogTitle:
        'Está prestes a desassociar a conta de cliente associada a este utilizador',
      irreversibleAction: 'Esta ação é irreversível. Deseja continuar?',
      linkDialogTitle:
        'Está prestes a modificar a conta de cliente associada a este utilizador',
      whichNewAccount: 'A que conta deseja associar o utilizador?',
      chooseAccount: 'Conta de associação',
      chooseLanguage: 'Idioma do convite',
      lostAccessCount:
        'O utilizador perderá acesso a {value, plural, one {{value} serviço} other {{value} serviços}}.',
      inheritedServicesFromGroup: ' - (serviço herdado do grupo {value})',
      alertInfo: {
        previousMembersList:
          'O utilizador não aparecerá mais na lista de utilizadores da conta de cliente {value}.',
        previousAccountAccessRights:
          'O utilizador perderá acesso aos seguintes serviços:',
        previousExternalisableAccessRights:
          'Se o utilizador ainda tiver acesso a um serviço externalizável de {value} antes da sua confirmação, ele será mantido.',
        newAccountMembersList:
          'O utilizador aparecerá nos membros da conta de cliente {value}.'
      },
      alertWarning: {
        retainedAccessRights: 'Os seguintes acessos serão mantidos:',
        unlinkGroups:
          'O utilizador será desassociado de todos os grupos da conta de cliente.',
        alertWarningTitle: 'Atenção',
        noMoreAccessToProfile:
          'Não poderá mais acessar este perfil de utilizador uma vez que ele seja desassociado da conta de cliente {value}.',
        removeAllAccessWarning:
          'Se deseja remover todos ou alguns dos acessos do utilizador {value} aos serviços mencionados acima, certifique-se de removê-los antes de desassociá-lo da conta. Pode realizar esta ação a partir da sua lista de ',
        awareness: 'Esta é uma ação sensível. Tenha cuidado antes de confirmar.'
      },
      switchAccount: {
        newAffiliationAccount: 'Nova conta de cliente',
        dialogTitle:
          'Está prestes a modificar a conta de cliente associada a este utilizador. Deseja continuar?',
        newInheritedServices:
          'O utilizador obterá automaticamente acesso aos serviços padrão desta conta de cliente.',
        servicesListLink: 'Ver a lista de serviços',
        alertInfoTitle: 'Para a nova conta de associação'
      },
      accessToProfileFromNewAccount:
        'Poderá acessar este perfil de utilizador a partir desta conta de cliente.',
      forSuperUserOnly: {
        newAccessToProfile:
          'Poderá acessar este perfil de utilizador a partir da lista de todos ',
        administratedUsers: 'os utilizadores que administra'
      },
      mutation: {
        linkToNewAccountSuccess:
          'O utilizador foi associado com sucesso à conta de cliente {value}.',
        unLinkSuccess:
          'O utilizador foi desassociado com sucesso da conta de cliente {value}.'
      }
    }
  },
  selectorList: {
    ipRangeType: {
      persons: 'Utilizador',
      apiKey: 'Chave API'
    }
  },
  error: {
    noAccountSelected: 'Nenhuma conta de cliente selecionada',
    selectAnAccount:
      'Selecione previamente um conta cliente para ver os detalhes deste serviço.',
    somethingHappened: 'Ocorreu um erro',
    comeBackLater: 'Tente novamente mais tarde',
    oops: 'Opa.',
    copyText: 'Copiar texto',
    textCopied: 'Texto copiado',
    details: 'Detalhes dos erros',
    noDataAvailable: 'Nenhum dado disponível!',
    infoWillBeDisplayedHere: 'As suas informações serão exibidas aqui.',
    form: {
      invalidEmail: 'Este endereço de e-mail é inválido',
      invalidEmailsLength: 'O número máximo de e-mails é 100',
      emailsDuplication: 'Existe pelo menos um e-mail duplicado',
      invalidIp: 'Verifique o formato',
      invalidIpRange: 'Intervalo de IP inválido',
      requiredField: 'O campo é obrigatório',
      min: 'O valor mínimo é {value}',
      max: 'O valor máximo é {value}',
      minLength: 'O texto é muito curto. Mín. {value} caracteres',
      maxLength: 'O texto é muito longo. Máx. {value} caracteres'
    },
    type: {
      signin: 'Erro de login',
      activation: 'Erro de ativação',
      requiredField: 'O campo é obrigatório.',
      userAlreadyExists: 'O utilizador já existe.',
      network: 'Ocorreu um erro de rede. Está conectado à Internet?',
      batch: 'A ação foi interrompida devido a vários erros.',
      E101: 'O utilizador já existe nesta conta de cliente.',
      E104: 'Vários utilizadores com estes parâmetros.',
      E121: 'A eliminação do próprio perfil não é permitida.',
      E123: 'Uma conta já existe com este endereço de e-mail.',
      E125: 'O endereço de e-mail é obrigatório.',
      E126: 'Não é permitido eliminar o último utilizador de uma conta.',
      E139: 'Não tem permissão para eliminar este perfil',
      E145: 'O domínio do e-mail está ligado a um diretório federado.',
      E247: 'A eliminação em massa não é permitida',
      E403: 'Acesso negado',
      E404: 'Página não encontrada',
      E500: 'Ocorreu um erro, tente novamente mais tarde.',
      HC0045: `O servidor demorou muito para responder.`,
      HC0045A: `Solicitação pendente, por favor, atualize o navegador para ver os resultados.`,
      invalidDateFormat: 'O formato da data é inválido',
      superUserAccessRefused: 'Não tem acesso de super utilizador'
    },
    sentence: {
      emailChange: 'O e-mail não pôde ser alterado',
      signin: 'Ocorreu um erro ao tentar fazer login',
      activation:
        'O seu link de ativação expirou ou o seu perfil já está ativado.',
      E403: 'Não tem permissão para acessar este conteúdo.',
      E404: 'A página solicitada não foi encontrada.'
    },
    illustrationAlt: {
      ballons: 'Ilustração de uma casa de papel que flutua com balões'
    }
  },
  menu: {
    activities: 'Atividades',
    apiKey: 'Chaves API',
    dashboard: 'Painel de controlo',
    groups: 'Grupos',
    management: 'Gestão',
    settings: 'Preferências',
    notifications: 'Notificações',
    services: 'Serviços',
    accessRights: 'Pedidos de acesso',
    users: 'Utilizadores',
    validateRequest: 'Validar um pedido',
    profileManager: 'Voltar à versão antiga',
    profile: 'Perfil',
    shortcuts: 'Atalhos',
    addUser: 'Convidar utilizadores',
    addGroup: 'Adicionar um grupo',
    organizations: 'Organizações'
  },
  myMenu: {
    profile: 'Perfil',
    logout: 'Terminar sessão'
  },
  banner: {
    pendingRequest: `A sua delegação temporária de administração termina em {hours}h {minutes}min`,
    bannerText: `Solicite a um administrador da conta a delegação temporária de administração.`,
    buttonText: `Solicitar delegação de administração`,
    accessRightRequestDialog: {
      title: `Solicitar acesso a um administrador`,
      noAdministratorYet:
        'Esta conta ainda não tem administrador. Entre em contato com o suporte.',
      text: `Digite o endereço de e-mail de um administrador da conta para fazer alterações. Enviaremos um e-mail de confirmação ao administrador para que ele valide sua solicitação.`,
      subtext: `O e-mail contém um link de validação válido por 5 minutos. Após a aceitação da sua solicitação, você terá acesso à administração da conta por 2 horas.`,
      sendEmail: `Enviar e-mail`,
      pendingRequest: `Foi enviado um e-mail ao administrador para validar o pedido de delegação.`,
      pendingRequestInfo: `O e-mail enviado ao administrador da conta contém um link válido por 5 minutos. Este link permite que ele aceite o seu pedido. Após a validação do seu pedido, você terá acesso temporário à administração da conta por 2 horas.`
    }
  },
  delegation: {
    success: 'Os direitos de administração foram concedidos com sucesso.',
    error:
      'Os direitos de administração não puderam ser adicionados. Por favor, entre em contato com o suporte.'
  },
  onBoarding: {
    step1H5: 'Atualizamos a gestão de contas.',
    step1Subtitle1: 'Vamos descobrir algumas funcionalidades da nova versão.',
    step2H5: 'Visualize facilmente as informações importantes',
    step2Subtitle1:
      'Um painel de controle sintético permite visualizar as principais informações das contas que administra.',
    step3H5: 'Gerencie os assinantes dos seus usuários de forma mais fácil.',
    step3Subtitle1:
      'As listas permitem que selecione facilmente vários usuários e serviços para abrir rapidamente o acesso aos seus utilizadores.'
  },
  page: {
    accountProfile: {
      title: 'Conta {value}',
      youConsult: 'Está a visualizar a conta do cliente',
      aboutTitle: 'Informações administrativas',
      membersSection: {
        membersTitle: 'Membros',
        members: 'utilizador(es)',
        seeAllMembers: 'Ver todos os utilizadores',
        notificationSettings: 'Preferências de notificação',
        notificationSettingsTooltip:
          'Os utilizadores são notificados por e-mail quando o acesso a um serviço lhes é adicionado.'
      },
      sections: {
        administrators: {
          title: 'Administradores',
          numberOfAdministrators: 'Número de administrador(es)',
          seeAllAdministrators: 'Ver todos os administradores'
        },
        about: {
          accountName: 'Nome da conta',
          accountCode: 'Código'
        },
        services: {
          servicesCatalog: 'Serviço(s) no catálogo',
          seeCatalog: 'Ver catálogo'
        },
        apikeys: {
          title: 'Chaves de API',
          activedApiKeys: 'Chaves Ativas',
          seeAll: 'Ver todas as chaves de API'
        },
        groups: {
          title: 'Grupo(s)',
          seeAllGroups: 'Ver todos os grupos'
        },
        pendingRequests: {
          title: 'Pedido(s) de acesso pendente',
          manageRequests: 'Gerir pedidos'
        }
      }
    },
    apiKeyProfile: {
      info: {
        title: 'Nome da chave:',
        subtitle: 'ID: {value}'
      },
      technicalInformations: 'Informações técnicas',
      contact: 'Contato',
      creator: 'Criador',
      creationDate: 'Data de criação',
      revocationDate: 'Data de revogação',
      relatedServices: 'Serviço relacionado',
      noRelatedServices:
        'Não há nenhum serviço associado a esta chave de API no momento',
      service: 'Serviço',
      customerAccount: 'Conta cliente',
      activities: 'Atividades',
      allActivities: 'Ver todas as atividades'
    },
    apikeyProfile: {
      menu: {
        general: 'Geral',
        audit: 'Atividades'
      },
      title: 'Chave de API {uuid}',
      activities: {
        title: 'Atividades',
        type: 'Tipo',
        date: 'Data',
        author: 'Autor'
      },
      activity: {
        type: `{value, select, ${ApiKeyEventType.Creation} {Criação} ${ApiKeyEventType.AccessGranted} {Acesso concedido} ${ApiKeyEventType.Revocation} {Revogação} ${ApiKeyEventType.AccessDenied} {Acesso negado} other {-}}`,
        emptyStateTitle: 'Não há histórico para esta chave de API',
        emptyStateText: 'Os próximos eventos aparecerão aqui'
      }
    },
    changeEmailValidation: {
      title: 'Validação do pedido de alteração de endereço de e-mail',
      subtitle:
        'Seu novo endereço de login foi atualizado com sucesso: {email}',
      goToCegid: 'Ir para a conta da Cegid'
    },
    accessRights: {
      newRequests: 'Novos pedidos de acesso',
      title: 'Pedidos de acesso',
      subtitle:
        'Consulte e gerencie os pedidos de acesso aos serviços dos seus usuários',
      empty: {
        title: 'Não há pedidos de assinatura',
        subtitle: 'Os próximos pedidos de acesso aparecerão aqui.'
      },
      acceptRequestSuccess: 'Acabou de aceitar o pedido',
      denyRequestSuccess: 'Acabou de negar o pedido',
      updateRequestStatusSuccess: 'O pedido foi atualizado com sucesso',
      changeExpirationDate: 'Modificar',
      cancelExpirationDate: 'Cancelar'
    },
    accountSelection: {
      title: 'Seleção da conta do cliente',
      whichAccount: 'Em qual <b>conta do cliente</b> você deseja trabalhar?',
      accountsFound:
        '{count, plural, zero {{count, number} conta de cliente encontrada} one {{count, number} conta de cliente encontrada} other {{count, number} contas de clientes encontradas}}',
      accountLabel: 'Código da conta de cliente',
      loginToAccount: 'Acessar a conta',
      noAccountsFound: 'Nenhuma conta encontrada'
    },
    administrators: {
      title: 'Administradores',
      index: {
        administrators: 'Administradores',
        email: 'E-mail',
        allStatus: 'Estado',
        language: 'Idioma'
      },
      accountAdministrators: 'Administradores da conta de cliente',
      emptyState: {
        title: 'Nenhum administrador definido',
        description: 'É importante definir um administrador para esta conta.'
      }
    },
    apiKeys: {
      title: 'Chaves de API',
      addApikeyTooltip:
        'Uma chave de API associada a um serviço permite conceder direitos de acesso a um programa de computador.',
      requireAccountSelectionTooltip:
        'Selecione previamente uma conta para gerar uma chave de API.',
      refreshTooltip: 'Atualizar a lista',
      apikeyHistory: 'Atividades da chave {name}',
      noApiKey: 'Nenhuma chave de API',
      futureApiKeysText: 'As futuras chaves de API geradas aparecerão aqui.',
      creator: 'Criador: {fullName}',
      undefinedCreator: 'Criador desconhecido',
      undefinedCreationDate: 'Data de criação desconhecida',
      revoke: 'Revogar',
      details: 'Detalhes',
      history: 'Atividades',
      revocationConfirmationApiKeyName:
        'Você está prestes a revogar a chave de API ',
      revocationConfirmationText:
        '. Esta ação será definitiva. Deseja continuar?',
      revocationSuccessMessage:
        'A chave de API {value} foi revogada com sucesso.',
      revokedTitle: 'Revogadas',
      activeTitle: 'Ativas',
      generate: {
        apiKey: 'Gerar uma chave de API',
        theApiKey: 'Gerar a chave de API',
        selectService: 'Selecionar um serviço',
        enterNewApiKeyName: 'Digite o nome de uma nova chave',
        apiKeyNameAlreadyExist:
          'Este serviço já possui uma chave de API com o mesmo nome.',
        mandatoryService: 'Você deve escolher um serviço.',
        mandatoryName: 'Você deve digitar um nome para esta nova chave.'
      },
      success: {
        title: 'Chave de API gerada com sucesso',
        yourApiKey: 'Sua chave de API',
        apiKeySecret: 'Segredo da chave de API',
        warning:
          'O segredo da chave de API gerada nunca mais poderá ser exibido.'
      }
    },
    createPersonGroup: {
      title: 'Adicionar um grupo',
      heading: 'Gerencie seus usuários de forma mais fácil',
      subheading:
        'Você pode classificar os usuários de sua empresa em grupos. Os serviços associados aos grupos serão automaticamente adicionados a cada usuário membro do grupo.',
      stepInformation: {
        name: 'Nome do grupo',
        description: 'Descrição',
        accountCode: 'Código da conta de cliente'
      },
      success: 'O grupo {groupName} foi adicionado com sucesso',
      groupAdded: '• Um grupo foi adicionado.'
    },
    dashBoard: {
      title: 'Painel de controle',
      index: {
        accountList: 'Lista de contas de clientes',
        administrators: 'Administradores',
        initializing: 'Inicialização',
        subscriptionRequests: {
          title: 'Novos pedidos de acesso aos serviços',
          noPendingRequests: 'Não há solicitações de acesso pendentes'
        },
        totalAdministrators: `{count, plural, one {{count, number} elemento} other {{count, number} elementos}}`
      },
      disabledUser: 'Este usuário não existe mais'
    },
    groupProfile: {
      title: 'Grupo {name}',
      youConsultGroup: 'Você está consultando o grupo',
      information: {
        title: 'Informação'
      },
      removeGroup: {
        removeGroupConfirmation:
          'Você está prestes a remover o grupo {groupName}. Os membros atuais do grupo perderão o acesso (via grupo) aos serviços associados. Deseja continuar?',
        removeSuccess: 'O grupo {groupName} foi removido com sucesso.'
      },
      creationBy: ' por {creator}',
      editProfile: {
        title: 'Editar grupo',
        name: 'Nome',
        description: 'Descrição'
      },
      services: {
        subscription: {
          removeConfirmation:
            'Você está prestes a remover o serviço {serviceName} do grupo {groupName}',
          addServicesTitle: 'Adicionar serviço(s) ao grupo',
          deleteMessage: 'O serviço foi removido do grupo.',
          successMessage: `{count, plural, one {{count, number} serviço foi adicionado ao grupo.} other {{count, number} serviços foram adicionados ao grupo.}} `
        },
        emptyState: {
          title: 'Nenhum serviço associado!',
          description: 'Os futuros serviços aparecerão aqui.'
        }
      },
      addServices: {
        emptyState: {
          title: 'A lista está vazia',
          description: 'Nenhum serviço disponível'
        },
        selection: `{count, plural, one {{count, number} serviço foi selecionado} other {{count, number} serviços foram selecionados}}`,
        completedSuccess:
          'Você adicionou {count, plural, one {{count, number} serviço} other {{count, number} serviços}}.'
      },
      members: {
        add: 'Adicionar um membro',
        removeDialogTitle: 'Confirmação de exclusão',
        removeMemberConfirmation:
          'Você está prestes a remover o usuário {fullName} do grupo {groupName}',
        removeSuccess: 'O usuário foi removido do grupo.',
        pageAllSelected:
          '{count, plural, one {{count, number} membro foi selecionado} other {{count, number} membros foram selecionados}} nesta página.',
        selectAll:
          'Selecionar {count, plural, one {{count, number} membro} other {{count, number} membros}}',
        maxSelectedExceeded:
          'Sua seleção excede o limite máximo de {count, plural, one {{count, number} membro} other {{count, number} membros}}. Por favor, selecione menos.',
        emptyState: {
          title: 'Nenhum membro associado!',
          description: 'Os futuros membros aparecerão aqui.'
        },
        addMembers: {
          emptyState: {
            title: 'Nenhum usuário pode ser adicionado a este grupo',
            description: 'Nenhum membro disponível'
          },
          completedSuccess:
            'Você adicionou {count, plural, one {{count, number} membro} other {{count, number} membros}}.',
          selection: `{count, plural, one {{count, number} membro foi selecionado} other {{count, number} membros foram selecionados}}`
        }
      }
    },
    groups: {
      title: 'Grupos',
      addGroupTooltip:
        'Crie grupos para gerenciar os direitos de acesso de um grupo de pessoas',
      help:
        'O grupo liga um conjunto de utilizadores a um conjunto de serviços.',
      index: {
        group: 'Grupo',
        account: 'Conta cliente'
      },
      emptyState: {
        title: 'Nenhum grupo criado',
        description: 'Comece por criar um novo grupo',
        superReaderDescription: 'Os grupos criados aparecerão aqui.'
      }
    },
    notifications: {
      title: 'Notificações',
      help:
        'Gerencie os e-mails que você e seus colaboradores desejam receber do Cegid Account',
      subtitle: 'Selecione os critérios que você prefere.',
      email: 'E-mail',
      newAccessRightsTitle: 'Adição de um serviço',
      newAccessRightsDescription:
        'Notifique seus usuários quando eles tiverem acesso a um novo serviço.',
      successfullySaved: `Suas preferências de notificação foram salvas com sucesso para o conta cliente {account}.`
    },
    organizationProfile: {
      pageTitle: 'Organização {value}',
      youConsultSentence: 'Está a visualizar a organização',
      uniqueIdentifier: 'Identificador único',
      aboutTitle: 'Informações administrativas',
      creationDate: 'Data de criação',
      seeAllDomains: 'Ver todos os domínios',
      menu: {
        general: 'Geral',
        domains: 'Domínios'
      },
      countDomains: 'Domínios geridos'
    },
    organizations: {
      title: 'Organizações',
      noOrganizationFound: 'Nenhuma organização encontrada'
    },
    organizationDomains: {
      title: 'Domínios de la organização {value}',
      domainsHelp: `
        Para que um domínio seja certificado por nós, pedimos-lhe que siga um procedimento de verificação.
        O objetivo deste procedimento é garantir a sua legitimidade no domínio através da inserção de um código de verificação nos seus registos DNS (do seu gestor de domínio).
        Trata-se normalmente do sítio onde adquiriu o seu domínio ou onde aloja o seu sítio Web. 
        Copie o código de verificação da nossa interface para o domínio em causa.
        Aceda ao seu gestor de domínio, nos registos DNS correspondentes ao domínio a verificar; 
        Crie um novo registo TXT; 
        Colar o código de validação. 
        Guarde o seu novo registo TXT.
        Um programa verificará a presença deste código de verificação no seu DNS e validará automaticamente o domínio com os nossos serviços.
        `,
      uniqueIdentifier: 'Identificador único {value}',
      domains: 'Domínios',
      statusType: `{value, select, ${DomainValidationMode.Strong} {Certificado} ${DomainValidationMode.None} {Validação pendente} ${DomainValidationMode.Weak} {Um confirmer}`,
      emptyState: 'Nenhum domínio associado',
      startAddingDomain: 'Adicionar um domínio'
    },
    services: {
      title: 'Serviços',
      tableTitle: 'Catálogo de serviços',
      help:
        'Seu catálogo de serviços permite visualizar todas as aplicações relacionadas ao seu contrato Cegid. Desta tabela, você pode modificar as propriedades de acesso a esses serviços, conceder direitos de acesso a seus usuários em um ou mais aplicativos, delegar a administração de um serviço e consultar os detalhes.',
      index: {
        family: 'Famílias',
        selectedServiceCount: `{count, plural, zero {{count, number} serviço selecionado} one {{count, number} serviço selecionado} other {{count, number} serviços selecionados}}`,
        accountDistribution: '{label} • {serviceCount, number} serviços',
        fromAccount: ' (de {accountSubscriber})',
        visibleServices: 'Serviços visíveis • {value}',
        notVisibleServices: 'Serviços não visíveis • {value}'
      },
      action: {
        subscribeExternalUser: 'Inscrever um usuário externo',
        subscribeExternalOwner: 'Adicionar um proprietário externo',
        updateServicesProperties: 'Alterar as propriedades dos serviços',
        updateServicesPropertiesSuccess:
          'As propriedades dos serviços foram atualizadas com sucesso'
      },
      createSubscription: {
        title: 'Inscrever um usuário',
        stepEmail: {
          email: 'E-mail',
          invalidEmail: 'Este endereço de e-mail é inválido.',
          checkEmail: `Verificar o endereço de e-mail`,
          userDoesNotExist: 'Este usuário não existe.'
        },
        stepInfo: {
          checkInfo: 'Verificar as informações'
        }
      },
      updateProperties: {
        title: 'Editar os serviços selecionados'
      },
      service: {
        availability: 'Disponibilidade',
        info: 'Informação'
      },
      table: {
        emptyStatePrimary: 'Nenhum serviço encontrado',
      }
    },
    servicesIpRestrictions: {
      index: {
        title: 'Adicionar um intervalo de IP restritivo',
        tableTitle: 'Intervalos de IP permitidos',
        type: 'Tipo',
        description: 'Descrição',
        startIp: 'IP de início',
        endIp: 'IP de fim',
        enabled: 'Ativo',
        disabled: 'Inativo',
        successAdd: 'O intervalo de IP foi adicionado',
        successRemove: 'O intervalo de IP foi removido',
        successUpdate: 'O intervalo de IP foi atualizado'
      }
    },
    servicesMembers: {
      tableTitle: 'Usuários com acesso ao serviço',
      emptyTitle: 'Nenhum usuário tem acesso ao serviço',
      noAvailableUserTitle: 'Nenhum usuário pode ser adicionado ao serviço',
      invite: 'Convidar',
      invitationResult: {
        external: 'O usuário foi convidado para o serviço',
        existing: 'Os usuários foram inscritos no serviço'
      },
      groups: {
        tableTitle: 'Grupos com acesso ao serviço',
        emptyTitle: 'Nenhum grupo tem acesso ao serviço',
        noAvailableGroupTitle: 'Nenhum grupo pode ser adicionado ao serviço',
        successAdd: 'O grupo foi adicionado ao serviço'
      }
    },
    externalserviceProfile: {
      title: `Nome do serviço :`,
      details: `{service} para a conta {targetAccount} `
    },
    serviceProfile: {
      settings: {
        title: 'Definições do serviço'
      },
      serviceMembers: {
        alert: {
          title: `Número máximo de acessos`,
          accessLimit: `O acesso a este serviço está limitado a {value} membros.`,
          groupWarning:
            'Tenha em atenção que os grupos podem ter acesso a este serviço e conter, eles próprios, vários membros utilizadores.'
        },
        add: 'Adicionar um membro',
        expirationDate: 'Data de expiração',
        removeExpiration: 'Remover a data de expiração',
        changeExpirationDate: 'Selecionar uma data de expiração',
        expirationDateAlert: {
          accessUntil: `Acesso até: `,
          noLimitForOneAccessTitle:
            'O acesso ao serviço para este usuário não será mais limitado no tempo.',
          noLimitForOneUserAndSeveralAccessTitle:
            'Os acessos do usuário aos serviços selecionados não serão mais limitados no tempo.',
          updateForOnServiceSeveralUsersTitle: `O acesso ao serviço não será mais limitado no tempo para sua seleção.`,
          expiryDateForOneUserSeveralServicesTitle:
            'O usuário poderá acessar os serviços selecionados até ',
          selectedUserCanAccessTheServiceUntil: `{count, plural, one {O acesso selecionado expirará} other {Os acessos selecionados expirarão}} em `,
          existingExpiryDateForOneUserSeveralAccess: `Uma data de expiração já está programada para um ou mais acessos entre sua seleção.`,
          limitedAccessForOneUserAndOneService:
            'O direito de acesso a este serviço expirará em '
        },
        tableTitle: 'Usuários com acesso ao serviço',
        emptyTitle: 'Nenhum usuário tem acesso ao serviço',
        giveAccess: 'Dar acesso ao serviço',
        invite: 'Convidar',
        removeAccess: 'Remover acesso ao serviço',
        removeOneOrMoreAccess:
          'Remover {count, plural, one {o acesso} other {os acessos}} ao serviço',
        confirmRemove:
          'Tem certeza de que deseja remover o acesso ao serviço {count, plural, one {do usuário selecionado} other {dos {count, number} usuários selecionados}}?',
        updateServiceEndDateForOneOrManyUser: `Escolha a data de expiração do serviço para {count, plural, one { o usuário selecionado} other { os {count, number} usuários selecionados}}.`,
        updateServicesEndDateForOneUser: `Escolha a data de expiração {count, plural, one { do serviço selecionado} other { dos {count, number} serviços selecionados}} para o usuário.`,
        invitationResult: {
          external: 'O usuário foi convidado para o serviço',
          existing: 'Os usuários foram inscritos no serviço'
        },
        manageAccess: 'Gerenciar acesso',
        remove: 'Remover',
        updateEndDateResult: {
          one:
            'O acesso deste usuário a este serviço foi atualizado com sucesso',
          many:
            'Os acessos ao serviço para estes usuários foram atualizados com sucesso'
        },
        removeResult: {
          one: 'O acesso deste usuário a este serviço foi removido com sucesso',
          many: 'Os acessos ao serviço foram removidos com sucesso',
          manyServiceOneUser:
            'Os acessos a este serviço foram removidos com sucesso'
        },
        groups: {
          tableTitle: 'Grupos com acesso ao serviço',
          emptyTitle: 'Nenhum grupo tem acesso ao serviço',
          noAvailableGroupTitle: 'Nenhum grupo pode ser adicionado ao serviço',
          successAdd: 'O grupo foi adicionado ao serviço'
        }
      },
      title: `Serviço - {service}`,
      youConsultService: `Você está consultando o serviço`,
      info: {
        title: `Nome do serviço : {value}`,
        defaultService: `Acesso atribuído a todos os usuários por padrão`,
        adminValidation: `Acesso ao serviço sujeito a validação por um administrador`
      },
      label: {
        defaultService: `Acesso automático`,
        adminValidation: `Validação Administrativa`,
        serviceCode: `Código do Serviço`
      },
      aboutService: `Sobre o serviço`,
      security: {
        formTitle: `Adicionar um intervalo de IP permitido`,
        formSubTitle: `{serviceName} / {accountName}`,
        descriptionPlaceholder: 'Adicionar uma descrição',
        heading: 'Opção de Segurança',
        subheading: `A opção de segurança permite restringir o acesso a um serviço a um ou mais intervalo(s) de IP permitido(s).`,
        addRange: 'Adicionar intervalo de IP',
        emptyTitle: 'Não há restrições',
        emptySubtitle: 'Use o formulário para criar restrições'
      },
      subscribers: 'Inscritos',
      owners: {
        ownersTitle: 'Proprietários',
        help:
          'O proprietário de um serviço pode administrar o serviço, adicionar/remover membros e alterar as propriedades do serviço',
        emptyTitle: `Este serviço ainda não tem proprietário`,
        owner: 'Proprietário',
        add: 'Adicionar um proprietário',
        sucess: 'Você adicionou {ownersCount} proprietários ao serviço'
      }
    },

    userProfile: {
      title: 'Perfil de {fullName}',
      yourProfile: 'Seu perfil',
      accountHelp: 'Número da conta do cliente Cegid',
      acceptedServices: {
        help: 'Agrupa todos os direitos de acesso do usuário.',
        updateServiceEndDateForUser: `Escolha a data de término de validade do serviço para o usuário selecionado`
      },
      deniedServices: {
        help:
          'Os pedidos de direitos de acesso a seguir foram negados por um administrador.'
      },
      suspendedServices: {
        help:
          'Os direitos de acesso a seguir foram suspensos por um administrador. Para mais informações, entre em contato com seu administrador.'
      },
      pendingServices: {
        help:
          'Estes pedidos de acesso estão aguardando validação por um administrador.'
      },
      accountDisabledAlert: {
        title: 'Conta desativada',
        message: 'Desculpe-nos, esta conta não está mais disponível.'
      },
      section: {
        account: {
          title: 'Conta'
        },
        administrators: {
          title: 'Administradores'
        },
        directories: {
          title: 'Diretórios'
        },
        personal: {
          title: 'Informações pessoais',
          fullname: 'Nome completo',
          password: 'Senha',
          securityLevel: 'Nível de segurança da senha:',
          email: 'E-mail',
          pendingEmailRequest:
            'Pedido de alteração de e-mail em andamento para: {value}',
          phone: 'Telefone',
          status: {
            activated: 'Ativado',
            created: 'Criado',
            toActivate: 'A ativar',
            disabled: 'Desativado'
          }
        },
        technical: {
          technicalInformation: 'Informações técnicas',
          immutableId: 'ID Imutável',
          immutableGuid: 'Guid Imutável',
          identifier: 'Identificador:',
          identityIdentifier: `Identificador de identidade:`,
          tenantId: 'ID do Inquilino:',
          objectId: 'ID do Objeto:',
          upn: 'UPN:'
        }
      },
      personalInformations: {
        activatedServices: `{value, plural, one {Serviço ativado} other {Serviços ativados}}`,
        pendingRequest: `{value, plural, zero {Pedido aguardando validação} one {Pedido aguardando validação} other {Pedidos aguardando validação}}`,
        cancelRequest: 'Cancelar pedido',
        cancelRequestConfirmationTitle: 'Confirmação de cancelamento',
        cancelRequestConfirmationText:
          'Tem certeza de que deseja cancelar o pedido de alteração de e-mail em andamento?',
        cancelRequestSuccess: `O pedido de alteração de e-mail foi cancelado`,
        renewRequest: `Reenviar e-mail`,
        resendRequestSuccess:
          'Um e-mail foi enviado para este endereço de e-mail para confirmação.',
        activatedSince: 'Ativo desde',
        userNotActivated: 'Aguardando ativação',
        seeAllServices: 'Ver todos os serviços ativados'
      },
      lastActivities: {
        title: 'Atividades recentes',
        activity: 'Atividade',
        result: 'Resultado',
        date: 'Data',
        activityType: `{value, select, ${AuditAction.Signin} {Login} ${AuditAction.ProfileEdited} {Perfil editado} ${AuditAction.PersonStatusChanged} {Status alterado} ${AuditAction.MigrationToMicrosoft} {Migração para Microsoft} ${AuditAction.PersonGroupCreation} {Grupo criado} other {-}}`,
        activityResult: `{value, select, ${AuditActionResult.Disabled} {Perfil desativado} ${AuditActionResult.SigninSucceeded} {Sucesso} ${AuditActionResult.PersonCreated} {Perfil criado} ${AuditActionResult.SigninFailed} {Falhou} ${AuditActionResult.PersonDisabled} {Perfil desativado} ${AuditActionResult.PersonActivated} {Perfil ativado} ${AuditActionResult.PersonToActivate} {Aguardando ativação} ${AuditActionResult.AuthenticationSucceeded} {Sucesso} ${AuditActionResult.Activated} {Ativado} other {-}}`,
        allActivities: 'Ver todas as atividades'
      },
      activities: {
        title: 'Atividades',
        filterLabel: 'Atividade',
        activityType: `{value, select, ${ActivityType.All} {Tudo} ${ActivityType.Creation} {Criação} ${ActivityType.Update} {Atualização} ${ActivityType.Connection} {Conexão} ${AuditAction.PersonGroupCreation} {Grupo criado} other {-}}`,
        emptyStateTitle: 'Nenhuma atividade registrada no momento',
        emptyStateText: 'Os próximos eventos aparecerão aqui.'
      },
      services: {
        service: 'Serviço',
        family: 'Família',
        numberOfSubscribers: `{subscribers}`,
        numberOfLicenses: `{licenses}`,
        withValidation: 'Validação',
        administratedValidation:
          'Serviço que requer validação do administrador',
        notAdministratedValidation:
          'Serviço que não requer validação do administrador',
        auto: 'Padrão',
        accessByDefault: 'Serviço adicionado automaticamente aos usuários',
        notAccessByDefault:
          'Serviço não adicionado automaticamente aos usuários',
        available: 'Disponível',
        unavailable: 'Indisponível',
        inheritedFromGroup: 'Herdado do grupo',
        accessToServiceSince: 'Acesso ao serviço desde',
        expired: 'Expirado',
        refused: 'Recusado',
        suspendedLabel: 'Suspenso',
        suspended: `{value, plural, zero {Serviço suspenso} one {Serviço suspenso} other {Serviços suspensos}}`,
        toValidate: 'Aguardando validação',
        add: 'Adicionar um serviço',
        removeConfirmation:
          'Você vai remover o acesso a {count, plural, one {{count, number} serviço} other {{count, number} serviços}}, deseja continuar?',
        removeSuccess: `Você cancelou a inscrição {count} serviço(s).`,
        pageAllSelected:
          '{count, plural, one {{count, number} serviço selecionado} other {{count, number} serviços selecionados}} nesta página.',
        selectAll:
          'Selecionar {count, plural, one {{count, number} serviço} other {{count, number} serviços}}',
        maxSelectedExceeded:
          'Sua seleção excede o limite máximo de {count, plural, one {{count, number} serviço} other {{count, number} serviços}}. Por favor, selecione menos.',
        completedPending:
          '{count, plural, one {Seu pedido de inscrição em {count, number} serviço foi enviado} other {Seus pedidos de inscrição em {count, number} serviços foram enviados}}.',
        pendingServices: 'Aguardando validação',
        authorizedAccess: `{value, plural, zero {Acesso autorizado} one {Acesso autorizado} other {Acessos autorizados} } `,
        deniedServices: `{value, plural, zero {Acesso recusado} one {Acesso recusado} other {Acessos recusados}}`,
        suspendedAccess: `{value, plural, zero {Acesso suspenso} one {Acesso suspenso} other {Acessos suspensos} } `,
        emptyStateTitle: 'Nenhum serviço encontrado',
        emptyStateText: 'Os futuros serviços inscritos aparecerão aqui.',
        emptyState: {
          title: 'Nenhum pedido pendente.',
          description: 'Seus pedidos pendentes aparecerão aqui.'
        },
        subscriptionEndDate: 'Acesso até {date} - {hour}',
        filterLabel: 'Estado',
        statusType: `{value, select, ${PersonAccessRightStatus.Accepted} {Autorizado(s)} ${PersonAccessRightStatus.ToValidate} {Aguardando validação} ${PersonAccessRightStatus.Refused} {Recusado(s)} other {Suspenso(s)}}`
      },
      editName: {
        firstName: 'Nome',
        lastName: 'Sobrenome',
        editName: 'Alterar nome'
      },
      editPhone: {
        phoneNumber: 'Telefone',
        mobileNumber: 'Telefone celular',
        editPhone: 'Alterar número de telefone'
      },
      editLanguage: {
        language: 'Idioma',
        editLanguage: 'Alterar idioma'
      },
      editSecurityLevel: {
        securityLevel: 'Nível de segurança',
        editSecurityLevel: 'Alterar nível de segurança'
      },
      editEmail: {
        editEmail: 'Alterar endereço de e-mail',
        email: 'E-mail',
        confirmation: {
          title: 'E-mail enviado',
          message: `Um e-mail foi enviado para o endereço de e-mail {email} com o assunto "Confirmação da alteração de e-mail". Clique no link contido nele para confirmar a alteração do endereço de e-mail.`,
          hint: `Se você não encontrar o e-mail, verifique a pasta de spam. Se ainda assim não encontrar o e-mail, verifique se o novo endereço de e-mail está correto e tente novamente.`
        }
      },
      editPassword: 'Alterar senha',
      updatedProfile: 'O perfil foi atualizado com sucesso',
      identityMerged: `Sua conta foi migrada com sucesso, você precisará fazer login com suas credenciais {dir}.`,
      beforeDirectoryMergeTitle: `Você será redirecionado para a página de login {dir}`,
      beforeDirectoryMergeConditions: `Para migrar sua conta Cegid para sua conta {dir}`,
      beforeDirectoryMergeText: `Você precisa fazer login com suas credenciais {dir} existentes. O endereço de e-mail usado pode ser o mesmo que o de sua conta Cegid.`,
      mergeAccountInfo: `Você deseja fazer login com uma conta {dir}`,
      mergeAction: `Vincular uma conta {value}`,
      yourInformation: 'Suas informações',
      mergeValidationText: `Após a migração, o login será feito com suas credenciais {dir} para todas as aplicações Cegid.`,
      mergeValidationSubText:
        'Para sincronizar sua conta {dir}, basta fazer login novamente.'
    },
    personGroupProfile: {
      tabServices: {
        addServiceButton: 'Adicionar um serviço'
      },
      about: {
        title: 'Sobre o grupo'
      }
    },
    users: {
      title: 'Usuários',
      help:
        'Nesta lista, você pode selecionar um ou vários usuários para revogá-los, exportá-los (formato Excel) ou conceder-lhes direitos de acesso a uma ou várias aplicações (serviços do seu catálogo).',
      name: 'Nome',
      firstname: 'Primeiro nome',
      action: {
        addUser: 'Convidar usuários',
        delete: {
          confirmation: `Você está prestes a excluir {count, plural, one {{count, number} usuário} other {{count, number} usuários}}, deseja continuar?`,
          success: `{count, plural, one {{count, number} usuário foi excluído.} other {{count, number} usuários foram excluídos.}}`
        }
      },
      table: {
        emptyStatePrimary: 'Nenhum usuário encontrado',
      },
      pageSelectedUserCount:
        '{count, plural, one {{count, number} usuário desta página foi selecionado} other {{count, number} usuários desta página foram selecionados}}.',
      selectUserCount:
        'Selecionar {count, plural, one {{count, number} usuário} other {{count, number} usuários}}',
      maxLimitExceeded:
        'Sua seleção excede o limite máximo de {count, plural, one {{count, number} usuário} other {{count, number} usuários}}. Por favor, selecione menos.',
      index: {
        administrators: 'Administrador',
        users: 'Usuário',
        email: 'E-mail',
        account: 'Conta cliente',
        creationDate: 'Data de criação',
        activationDate: 'Data de ativação',
        language: 'Idioma',
        phoneNumber: 'Telefone',
        mobileNumber: 'Telefone celular',
        titleCode: 'Título',
        allGroups: 'Todos os grupos',
        allRoles: 'Todos os papéis',
        allStatus: 'Estado',
        item: {
          email: '{value, select, null { } other {{value}} }',
          group: '{value, select, TOCLASSIFY {Classificar} other { }}',
          role: '{value, select, null { } other {{value}} }',
          status:
            '{value, select, CREATED {Criado} TOACTIVATE {Para ativar} ACTIVATED {Ativo} DISABLED {Inativo} }'
        },
        selectedUserCount: `{count, plural, one {{count, number} usuário selecionado} other {{count, number} usuários selecionados}}`,
        userStatus: {
          actives: 'Ativos • {count, number}',
          pending: 'Aguardando ativação • {count, number}',
          created: 'Criados • {count, number}'
        }
      },
      subscription: {
        success: {
          meToOne: `Você se inscreveu em 1 serviço.`,
          meToMany: `Você se inscreveu em {servicesCount} serviços.`,
          oneToOne: `O usuário foi inscrito no serviço.`,
          oneToMany: `O usuário foi inscrito em {servicesCount} serviços.`,
          manyToOne: `{usersCount} usuários foram inscritos no serviço.`,
          manyToMany: `{usersCount} usuários foram inscritos em {servicesCount} serviços.`
        }
      },
      export: {
        lists: 'Listas',
        columns: 'Colunas',
        help: 'Selecione apenas as colunas que deseja exportar.',
        needServiceList: 'Selecione apenas as listas que deseja exportar.',
        itemToExport: 'Itens a serem exportados',
        withServiceList: 'Lista de serviços por usuário',
        accountCode: 'Código do conta cliente',
        accountLabel: 'Rótulo do conta cliente',
        creationDate: 'Data de criação',
        email: 'E-mail',
        securityLevel: 'Nível de segurança',
        status: 'Estado',
        services: 'Lista de serviços por usuários'
      }
    }
  },
  profile: {
    menu: {
      access: 'Acessos',
      general: 'Geral',
      services: 'Serviços',
      restrictions: 'Restrições',
      activities: 'Atividades',
      members: 'Membros',
      domains: 'Domínios'
    },
    about: {
      title: 'Sobre',
      creationDate: 'Data de Criação',
      directories: 'Diretórios',
      language: 'Idioma',
      outsourceableService: {
        title: 'Serviço terceirizável'
      },
      serviceFamily: 'Família de Serviço'
    }
  },
  securityLevel: {
    weak: 'Fraco',
    medium: 'Médio',
    high: 'Forte'
  },
  tableCollectionColumnSelection: {
    title: 'Opções de Colunas',
    hint:
      'Selecione as colunas a serem exibidas e arraste para reorganizá-las. Nem todas as colunas podem ser exibidas em telas menores.'
  },
  about: {
    google: {
      title: 'Conectar-se à Cegid Cloud com sua conta do Google',
      subtitle:
        'A Cegid Cloud é o ecossistema de aplicativos e serviços online da Cegid',
      connectToCegidProducts: `Você pode se conectar à plataforma Cegid Cloud e a todos os serviços que ela suporta usando sua conta do Google.`,
      followSteps: `Para isso, basta seguir as etapas de acordo com sua necessidade:`,
      whatYouWant: 'O que você deseja fazer?',
      connectToCegidApplicationWithYourGoogleAccount:
        'Associar sua conta Cegid existente à sua conta do Google',
      activateYourCegidAccountWithGoogle:
        'Ativar sua nova conta Cegid com sua conta do Google',
      youAlrearyHaveACegidAccount:
        'Você já tem uma conta Cegid e deseja se identificar através da sua conta do Google: Mescle suas contas!',
      youNeedHelpWriteTo: `Se precisar de ajuda para ativar ou conectar sua conta Cegid à sua conta do Google, você pode escrever para `,
      forInformation: `Para mais informações, você pode consultar `,
      ourCGV: `nossos termos e condições de uso `,
      andOur: `e `,
      privacyPolitic: `política de privacidade e cookies`,
      userActivation: {
        youReceiveAnEmailToActivateYourAccount:
          'Você recebeu um e-mail para ativar sua conta Cegid e deseja usar as credenciais da sua conta do Google ao fazer login nos aplicativos Cegid?',
        step1Title: '1. Você recebeu um e-mail',
        step1Content: 'Clique no link de ativação para continuar o processo.',
        step2Title:
          '2. Você será redirecionado para a página de ativação de conta',
        step2Content: `Você pode ativar sua conta Cegid usando sua conta do Google clicando no botão "Continuar com o Google"`,
        step3Title:
          '3. Você será redirecionado para a página de autenticação do Google',
        step3Content: 'Insira as credenciais da sua conta do Google.',
        step4Title:
          '4. Você será redirecionado para a interface do Cegid Compte',
        step4Content: 'Sua conta foi ativada com sucesso.',
        step4Success:
          'Você agora deve estar conectado ao Cegid Compte e acessar suas informações.'
      },
      userUpdateIdp: {
        step1: '1. Faça login no aplicativo Cegid Compte e acesse seu perfil',
        step2: '2. Na seção diretórios, você pode vincular sua conta do Google',
        step3:
          '3. Clique no botão "Vincular uma conta do Google", uma mensagem será exibida. Clique em "Entendi" para ser redirecionado para a tela de login do Google.',
        step4:
          '4. Insira seu e-mail vinculado à conta do Google que você deseja usar para fazer login nos aplicativos Cegid',
        step5:
          '5. Verifique suas informações, para continuar com a fusão de contas, clique em "Migrar"',
        step5End: 'Você será desconectado do aplicativo.',
        step6:
          '6. Faça login com o e-mail da conta do Google que você acabou de vincular ao seu perfil Cegid.',
        step7:
          'Você deve estar conectado ao seu perfil com suas informações atualizadas.'
      }
    }
  },
  helperText: {
    groupNameRequired: 'O nome do grupo é obrigatório',
    groupDescriptionRequired: 'A descrição do grupo é obrigatória'
  },
  dataPrivacy: {
    title: 'Política de proteção de dados',
    userProfileDataPrivacy:
      'A Cegid SAS processa seus dados pessoais como parte da gestão da sua Conta Cegid. Você tem o direito de acessar, retificar, excluir e portar seus dados, bem como o direito de se opor e restringir certos processamentos. Para mais informações sobre o processamento e como exercer seus direitos, você pode consultar a política de proteção de dados.',
    sentence1:
      'As informações pessoais fornecidas na Cegid Account são processadas pela Cegid SAS para permitir que você acesse os serviços Cegid no contexto da relação contratual.',
    sentence2:
      'Seus dados podem ser comunicados às filiais do responsável pelo tratamento, bem como a prestadores de serviços externos encarregados da implementação do tratamento ou a terceiros autorizados em caso de solicitações por autoridades competentes. É necessário fornecer um identificador (e-mail ou número de matrícula, por exemplo). Na falta disso, o tratamento não poderá ser realizado.',
    sentence3:
      'Seus dados pessoais são mantidos por {value} anos a partir da sua última atividade registrada na conta (seu último login, por exemplo).',
    sentence4:
      'Seus dados pessoais podem ser transferidos para países localizados fora da União Europeia para as finalidades detalhadas acima. Essas transferências são objeto de um quadro jurídico específico para garantir que esses dados sejam protegidos por um nível adequado de proteção.',
    sentence5:
      'Em conformidade com a regulamentação aplicável em matéria de proteção de dados pessoais, você tem o direito de acessar, retificar, excluir e portar seus dados, bem como o direito de oposição e limitação a todos os dados que lhe dizem respeito.',
    sentence6:
      'Esses direitos podem ser exercidos escrevendo para o nosso delegado de proteção de dados enviando sua solicitação para dataprivacy@cegid.com. Você pode exercer seu direito de recurso a qualquer momento junto à Autoridade competente em matéria de proteção de dados pessoais (CNIL).'
  }
});
