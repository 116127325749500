// Number of cards displayed by default at first rendering
export const CARD_PER_PAGE_DEFAULT = 5;

// Number of cards displayed by default at first rendering
export const APIKEY_CARD_PER_PAGE_DEFAULT = 16;

// Table's rows default number displayed
export const ROW_PER_PAGE_DEFAULT = 25;

// Table's rows options for number of rows displayed
export const ROW_PER_PAGE_OPTIONS = [ROW_PER_PAGE_DEFAULT, 50, 100];

// number of characters typed by the user before the search starts
export const MIN_SEARCH_LENGTH = 3;

// maximum number of selected items (query optimization)
export const MAX_SELECTED = 2000;

// ms to wait for after user input and before making a request
export const DEBOUNCE_DELAY = 500;

/* Time allocated to simulate some latency in server response to test loading scenarios */
export const DEFAULT_TEST_LATENCY_DELAY = 100;

/* Maximum quantity of users that can be added at once */
export const MAX_USERS_ON_CREATE = 100;

/* Maximum quantity of account to display the account selector in header */
export const ACCOUNTS_LIMIT_TO_HEADER_NAVIGATION = 20

/* Minimum quantity of account to display a search bar on accounts */
export const ACCOUNTS_MIN_TO_SEARCH_BY_ACCOUNT = ACCOUNTS_LIMIT_TO_HEADER_NAVIGATION + 1;

/* Maximum days for user data retention */
export const MAX_USER_DATA_RETENTION = 3;

export const AVATAR_COLOR_PALETTE =  [ "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#FF9800", "#FF5722", "#795548", "#607D8B" ];