import { useContext, useState } from 'react';
import useTranslate from '../../Hooks/useTranslate';
import { Helmet } from 'react-helmet';
import AccessRightsCardsContainer from 'Components/AccessRightsCards/AccessRightsCards.container';
import { Container as CustomContainer } from 'Theme/components';
import { useStyles } from './AccessRights.styles';
import { SearchByUser } from 'Components/Shared/SearchBars/SearchByUser';
import { Typography, Grid, useMediaQuery, Box } from '@mui/material';
import TestIds from 'Tests/TestIds';
import { LocalContext } from 'Context/Local.context';
import mediaQueries from 'Theme/constants/mediaQueries';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';
import useSpecificAccessRightsPosition from 'Hooks/useSpecificAccessRightsPosition';

const AccessRights = () => {
  const ts = useTranslate();
  const { currentAccount } = useContext(LocalContext);
  const selectedAccountCode = currentAccount?.code ?? 'default';
  const classes = useStyles();
  const isMobile = useMediaQuery(mediaQueries.mobile);
  const { hasAccessToAccounts } = useCurrentUserInfo();

  const { headerPosition, contentPosition } = useSpecificAccessRightsPosition({isMobile, hasAccessToAccounts});

  const [currentSearch, setCurrentSearch] = useState<string | undefined>(
    undefined
  );
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  const changeSearchedValue = (value: string) => {
    setCurrentSearch(value);
  };

  const initSearch = () => {
    setCurrentSearch(undefined);
    setSelectedUserId(undefined);
  };

  const updateChosenID = (value: string) => {
    setSelectedUserId(value);
  };


  return (
    <>
      <Helmet title={ts('page.accessRights.title')} />
      <CustomContainer>
        <Box
          className={classes.accessRightsTopSection}
          sx={{
            top: headerPosition,
          }}
        >
          {!isMobile && (
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.accessRightsRequestsPageTitle}
            >
              <Grid item>
                <Typography
                  variant="h4"
                  paragraph
                  align="left"
                  color="textPrimary"
                  data-testid={TestIds.pages.accessRightsRequests.title}
                >
                  {ts('page.accessRights.newRequests')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="left"
                  data-testid={TestIds.pages.accessRightsRequests.subtitle}
                >
                  {ts('page.accessRights.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          )}

          <SearchByUser
            accountCode={selectedAccountCode}
            onSearch={changeSearchedValue}
            onItemSelect={updateChosenID}
            onInit={initSearch}
          />
        </Box>
        <Box
          className={classes.accessRightsCardsContainer}
          sx={{
            top: contentPosition,
          }}
        >
          <AccessRightsCardsContainer
            selectedAccountCode={selectedAccountCode}
            currentSearch={currentSearch}
            selectedUserId={selectedUserId}
          />
        </Box>
      </CustomContainer>
    </>
  );
};

export default AccessRights;
