import { createTheme } from '@mui/material/styles';
import { colors, palette, shape, typography, zIndex } from './constants';
import { CdsColorActionNeutralActive, CdsColorActionPrimaryActive, CdsColorActionPrimaryDisabled, CdsColorBackgroundPrimaryMainDisabled, CdsColorBackgroundWhiteMain, CdsColorBrandNeutral95, CdsColorBrandSecondary50, CdsColorInteractionPrimaryHovered, CdsColorSpecificBackdropNeutral, CdsEffectElevationAmbientColor, CdsEffectElevationPenumbraColor, CdsEffectElevationUmbraColor, CdsSizeRadius, CdsSizeSpacing, CdsSizeSpecificS, CdsSizeSpecificXxs, CdsTypographyHeadline2FontSize } from '@cegid/design-tokens';

export default createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colors.white
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 44, 82, 0.5)'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: `${zIndex.appBar + 1} !important` as any
        },
        paper: {
          borderRadius: CdsSizeRadius
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '16px !important'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 'unset',
          borderWidth: '0px 0px thin',
          borderStyle: 'solid',
          borderColor: 'rgba(0, 44, 82, 0.08)'
        },
        inset: {
          marginLeft: CdsSizeSpecificXxs
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: `0px 1px 1px 0px ${CdsEffectElevationUmbraColor} , 0px 2px 1px -1px ${CdsEffectElevationPenumbraColor}, 0px 1px 3px 0px ${CdsEffectElevationAmbientColor}`,
          height: '24px',
        },
        extended: {
          '&$sizeSmall': {
            padding: '0 12px !important',
            height: '28px',
            fontSize: '12px'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: CdsSizeRadius
        },
        elevation4: {
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          'z-index': `${zIndex.snackbar} !important`,
          backgroundColor: CdsColorSpecificBackdropNeutral
        },
        paper: {
          borderRadius: CdsSizeRadius
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          borderTop: `1px solid ${CdsColorBrandNeutral95} !important`,
          flexDirection: 'column'
        }
      }
    },
    MuiAccordionActions: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: '0',
          paddingRight: CdsSizeSpecificS,
          background: CdsColorBackgroundWhiteMain,
          width: '100%',
          borderTop: '1px solid rgba(224, 224, 224, 1)'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          '&$expanded': {
            margin: 0
          }
        },
        root: {
          '&$focused': {
            backgroundColor: 'unset !important'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            borderRadius: CdsSizeRadius,
            backgroundColor: 'unset !important'
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginBottomRight: {
          bottom: '64px !important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          // borderRadius: CdsSizeRadius,
          boxShadow: 'none',
          color: colors.white,
          textTransform: 'none'
        },
        outlinedSizeSmall: {
          padding: `6px ${CdsSizeSpacing}`
        },
        containedSizeSmall: {
          padding: `6px ${CdsSizeSpacing}`
        },
        outlined: {
          /*
           * Our buttons have a different padding than @material-ui's default.
           */
          padding: `10px ${CdsSizeSpecificXxs}`
        },
        outlinedInherit: {
          color: 'inherit'
        },
        outlinedPrimary: {
          border: '1px solid rgba(0, 44, 82, 0.2)'
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colors.blue[400]
          },
          '&:disabled': {
            backgroundColor: CdsColorBackgroundPrimaryMainDisabled,
            color: CdsColorActionPrimaryDisabled
          }
        },
        root: {
          borderRadius: CdsSizeRadius,
          /*
           * Our default buttons' color is lighter than @material-ui's default black color.
           */
          color: palette.text.secondary,
          textTransform: 'none',
          /*
           * Our buttons have a different padding than @material-ui's default.
           * Apart from the size classes, "root", "text", and "outlined" are the only ones to define a padding:
           * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
           */
          padding: `10px ${CdsSizeSpecificXxs}`,
          '&:hover': {
            backgroundColor: CdsColorInteractionPrimaryHovered
          }
        },
        text: {
          /*
           * Our buttons have a different padding than @material-ui's default.
           */
          padding: `10px ${CdsSizeSpecificXxs}`,
          textTransform: 'none'
        },
        textPrimary: {
          color: CdsColorActionPrimaryActive
        },
        textInherit: {
          color: colors.grey[500]
        },
        textSecondary: {
          color: CdsColorBrandSecondary50
        },
        startIcon: {
          marginRight: CdsSizeSpecificXxs
        },
        endIcon: {
          marginLeft: CdsSizeSpecificXxs
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1102
        },
        paperAnchorLeft: {
          boxShadow: 'none'
        },
        paperAnchorDockedBottom: {
          borderTop: 'none',
          boxShadow:
            '0px 5px 0 rgba(31,37,50,.2), 0 -2px 24px rgba(0, 0, 0, 0.2)'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: `${CdsSizeSpecificXxs} !important`,
          paddingRight: `${CdsSizeSpacing} !important`
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        centered: {
          alignItems: 'center'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: `4px ${CdsSizeSpecificXxs}`
        },
        head: {
          ...typography.body2,
          color: palette.text.secondary,
          lineHeight: 3
        },
        body: {
          ...typography.body2,
          color: palette.text.primary
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: CdsTypographyHeadline2FontSize,
          '&$selected': {
            backgroundColor: colors.blue[30]
          },
          '&$selected:hover': {
            backgroundColor: colors.grey[250]
          },
          '&:hover': {
            backgroundColor: colors.grey[200]
          }
        },
        footer: {
          '&:hover': {
            backgroundColor: 'unset'
          }
        },
        head: {
          '&:hover': {
            backgroundColor: 'unset'
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          marginLeft: -24
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          ...typography.caption,
          color: palette.text.secondary
        },
        selectRoot: {
          marginLeft: 0,
          marginRight: 4
        },
        actions: {
          color: palette.text.secondary,
          marginLeft: 4
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: CdsSizeSpacing,
          color: 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0, 44, 82, 0.06)'
          }
        },
        sizeSmall: {
          padding: CdsSizeSpecificXxs,
          height: '0.8em',
          width: '0.8em',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: CdsSizeRadius
        },
        adornedEnd: {
          borderRadius: CdsSizeRadius
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft: CdsSizeSpecificXxs
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          paddingLeft: CdsSizeSpecificXxs
        },
        outlined: {
          paddingLeft: 0
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...typography.body2
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: CdsSizeSpecificXxs
        },
        contained: {
          marginLeft: 0
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '@media (min-height: 354px)': {
            maxHeight: 338
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 'auto'
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        dotActive: {
          backgroundColor: colors.white
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: CdsColorActionNeutralActive
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          margin: `${CdsSizeSpacing} 0 !important`,
          background: 'rgb(0, 44, 82)',
          borderRadius: CdsSizeRadius,
          fontWeight: 400,
          lineHeight: '1.4em',
          fontSize: '0.875em',
          padding: '8px 16px'
        },
        tooltipPlacementRight: {
          margin: `0 ${CdsSizeSpacing} !important`
        },
        tooltipPlacementBottom: {
          margin: `${CdsSizeSpacing} 0 !important`,
          background: 'rgb(0, 44, 82)',
          borderRadius: CdsSizeRadius,
          fontWeight: 400,
          lineHeight: '1.4em',
          fontSize: '0.875em',
          padding: '8px 16px'
        },
        tooltipPlacementLeft: {
          margin: `0 ${CdsSizeSpacing} !important`
        }
      }
    }
  },
  palette,
  typography,
  shape,
  zIndex
});
