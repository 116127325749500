import { AVATAR_COLOR_PALETTE } from 'Theme/config';
import { CdsColorBackgroundNeutralMainDisabled } from '@cegid/design-tokens';

interface Props {
  identifier: string;
}
export const useAvatarColor = ({ identifier }: Props) => {
  const listOfNumber = identifier?.match(/\d+/g) as RegExpMatchArray as string[] ?? null;

  if (listOfNumber === null) {
    return CdsColorBackgroundNeutralMainDisabled;
  } else {
    // Calculate the sum of the elements in listOfNumber
    let sum = 0;
    for (const number of listOfNumber) {
      sum += parseInt(number);
    }
    // Calculate the index using the modulus operator
    const index = sum % AVATAR_COLOR_PALETTE.length;

    // Return the color at the calculated index
    return AVATAR_COLOR_PALETTE[index];
  }
};
