import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import ApiStateTabContainer from './ApiStateTab.container';

export default function TabRevoked(props: Readonly<{ accountCode: string }>) {
  return (
    <ApiStateTabContainer
      apiStatus={ApiKeyState.Revoqued}
      accountCode={props.accountCode}
    />
  );
}
