import { useQuery } from '@apollo/client';
import { BubbleChart } from '@mui/icons-material';
import { GetServiceSubscriptionsCount } from 'Apollo/queries/service/GetServiceSubscriptionsCount';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import CardCounter from 'Theme/components/CardCounter';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';

function ServicesCounter() {
  const ts = useTranslate();
  const { accountCode } = useParams<{ accountCode: string }>();

  const initialVariables = {
    page: { first: 0, offset: 0 },
    accountCode: accountCode === 'all' ? null : accountCode,
    apiKeyEligibleOnly: false
  };
  const { data, loading, error } = useQuery<GetServiceSubscriptionsCountData>(
    GetServiceSubscriptionsCount,
    { variables: initialVariables }
  );

  const totalServicesCard = data?.serviceSubscriptions.totalCount ?? 0;
  return (
    <CardCounter
      testId={TestIds.pages.services.cardTotalCount}
      title={ts('menu.services')}
      counter={
        error ? (
          <Typography variant="caption" color="error">
            {ts('common.error.disabledResult')}
          </Typography>
        ) : (
          totalServicesCard
        )
      }
      loading={loading}
      icon={<BubbleChart />}
    />
  );
}

export default ServicesCounter;
