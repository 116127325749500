import { Typography, Box } from '@mui/material';
import React from 'react';

interface Props {
  error: string;
  visible: boolean;
}

const ErrorMessage = ({ error, visible }: Props) => {
  return (
    <Box style={{ height: 38 }}>
      {!visible || !error ? null : (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ErrorMessage;
