import { gql } from '@apollo/client';

export const InvitePersonsToAccount = gql`
  mutation InvitePersonsToAccount(
    $accountCode: String!
    $emails: [String!]
    $language: String!
  ) {
    invitePersonsToAccount(
      accountCode: $accountCode
      emails: $emails
      language: $language
    ) {
      error {
        code
        message
      }
      userEmail
      userImmutableId
    }
  }
`;
