import { ServiceSubscriptionOrder } from 'Apollo';
import useTranslate from './useTranslate';
import Skeleton from 'Theme/components/Skeleton';
import { TableCollectionColumn } from '../Components/Shared';
import ServiceProfileMembersLink from '../Components/ServiceProfileMembersLink';
import CellService from 'Components/Shared/CellService';
import CellAdministratedValidation from 'Components/Shared/CellAdministratedValidation';
import CellAccessByDefault from 'Components/Shared/CellAccessByDefault';

export interface IServiceColumnsConfig {
  isServiceHidden?: boolean;
  isDescriptionHidden?: boolean;
  isFamilyHidden?: boolean;
  isAccountHidden?: boolean;
  isSubscribersHidden?: boolean;
  isAdminValidationHidden?: boolean;
  isAccessByDefaultHidden?: boolean;
}

export const defaultServicesConfig: IServiceColumnsConfig = {
  isServiceHidden: false,
  isDescriptionHidden: false,
  isFamilyHidden: false,
  isAccountHidden: false,
  isSubscribersHidden: false,
  isAdminValidationHidden: false,
  isAccessByDefaultHidden: false
};

export const useColumnsServices = (
  columnsConfig: IServiceColumnsConfig
): TableCollectionColumn<IServiceSubscriptionWithCount>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'service',
      width: 0.1,
      orderBy: {
        asc: ServiceSubscriptionOrder.NameAsc,
        desc: ServiceSubscriptionOrder.NameDesc
      },
      hidden:
        columnsConfig.isServiceHidden ?? defaultServicesConfig.isServiceHidden,
      renderHead: () => ts('page.userProfile.services.service'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => <CellService serviceSubscription={serviceSubscription} />
    },
    {
      key: 'description',
      width: 0.35,
      hidden:
        columnsConfig.isDescriptionHidden ??
        defaultServicesConfig.isDescriptionHidden,
      renderHead: () => ts('common.terminology.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => serviceSubscription.service.description ?? ''
    },
    {
      key: 'family',
      width: 0.1,
      orderBy: {
        asc: ServiceSubscriptionOrder.FamilyAscThenNameAsc,
        desc: ServiceSubscriptionOrder.FamilyDescThenNameAsc
      },
      hidden:
        columnsConfig.isFamilyHidden ?? defaultServicesConfig.isFamilyHidden,
      renderHead: () => ts('page.userProfile.services.family'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => serviceSubscription.service.family
    },
    {
      key: 'account',
      width: 0.2,
      orderBy: {
        asc: ServiceSubscriptionOrder.AccountLabelAscThenNameAsc,
        desc: ServiceSubscriptionOrder.AccountLabelDescThenNameAsc
      },
      hidden:
        columnsConfig.isAccountHidden ?? defaultServicesConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) =>
        serviceSubscription.service.groupCode === 'ADM-2'
          ? serviceSubscription.targetAccount!.name
          : serviceSubscription.account.name
    },
    {
      key: 'subscribers',
      width: 0.1,
      hidden:
        columnsConfig.isSubscribersHidden ??
        defaultServicesConfig.isSubscribersHidden,
      renderHead: () => ts('common.service.access'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscriptionWithCount;
      }) => (
        <ServiceProfileMembersLink serviceSubscription={serviceSubscription} />
      )
    },
    {
      key: 'administratedValidation',
      width: 0.05,
      hidden:
        columnsConfig.isAdminValidationHidden ??
        defaultServicesConfig.isAdminValidationHidden,
      renderHead: () => ts('page.userProfile.services.withValidation'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (
        <CellAdministratedValidation
          serviceSubscription={serviceSubscription}
        />
      )
    },
    {
      key: 'accessByDefault',
      width: 0.1,
      hidden:
        columnsConfig.isAccessByDefaultHidden ??
        defaultServicesConfig.isAccessByDefaultHidden,
      renderHead: () => ts('page.userProfile.services.auto'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => <CellAccessByDefault serviceSubscription={serviceSubscription} />
    }
  ];
};
