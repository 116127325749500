import { gql } from '@apollo/client';
import { LightApiKeyFragment } from '../../fragments/apikey/LightApiKey';

export const GetApiKeys = gql`
  query GetApiKeys(
    $accountFilter: String
    $page: PageInput
    $serviceFilter: String
    $state: ApiKeyState
  ) {
    apiKeys(
      accountFilter: $accountFilter
      page: $page
      serviceFilter: $serviceFilter
      state: $state
    ) {
      edges {
        node {
          ...LightApiKey
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LightApiKeyFragment}
`;
