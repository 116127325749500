import { gql } from '@apollo/client';

/** Return current user's administrated services count */
export const GetServiceSubscriptionsCount = gql`
  query GetServiceSubscriptionsCount(
    $accountCode: String
    $page: PageInput
    $apiKeyEligibleOnly: Boolean!
  ) {
    serviceSubscriptions(
      accountCode: $accountCode
      page: $page
      apiKeyEligibleOnly: $apiKeyEligibleOnly
    ) {
      totalCount
    }
  }
`;
