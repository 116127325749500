import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import ServicesAccountDistribution from './ServicesAccountDistribution';
import useTranslate from 'Hooks/useTranslate';
import ServicesCounter from './ServicesCounter/ServicesCounter';
import { LocalContext } from 'Context/Local.context';
import { useContext } from 'react';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';
import ServicesTableContainer from './ServicesTable/ServicesTable.container';


const Services = () => {
  const ts = useTranslate();
  const { allAccountsSelected } = useContext(LocalContext);
  const { isAdminOfOnlyOneAccount } = useCurrentUserInfo();


  return (
    <>
      <Helmet title={ts('page.services.title')} />
      {allAccountsSelected && !isAdminOfOnlyOneAccount && (
        <Grid
          container
          spacing={2}
          sx={{ display: { xs: 'none', sm: 'flex' }, marginBottom: '12px' }}
        >
          <Grid item xs={6}>
            <ServicesCounter />
          </Grid>
          <Grid item xs={6}>
            <ServicesAccountDistribution />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <ServicesTableContainer />
        </Grid>
      </Grid>
    </>
  );
};

export default Services;
