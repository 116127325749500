import { ApolloError } from '@apollo/client';

/**
 * Set a new message for a specific error code
 * @param error ApolloError
 * @param targetCode String - Is the code of the error to change
 *
 * @returns boolean
 */

export const checkErrorMessage = (
    error: ApolloError,
    targetCode: string,
  ): boolean => {
    let containError = error.graphQLErrors.filter(e => e.extensions?.code === targetCode);
    if(containError.length > 0) return true;
    return false;
  };