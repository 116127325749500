import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import { GetAccounts, GetLanguages } from 'Apollo';
import { useContext, useState } from 'react';
import { InvitePersonsToAccount } from 'Apollo/mutations/account/InvitePersonsToAccount';
import FormUsersCreationSkeleton from './FormUsersCreation.skeleton';
import FormUsersCreationSuccess from './FormUsersCreation.success';
import FormUsersCreation from './FormUsersCreation';
import { LocalContext } from 'Context/Local.context';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { setErrorMessage } from 'Tools/setErrorMessage';

type Props = {
  onCancel(): void;
};

export type TUsersCreation = {
  emails: string[];
  account: string;
  language: string;
};

const FormUsersCreationContainer = (props: Props) => {
  const { currentAccount, availableAccounts } = useContext(LocalContext);
  const { isAdminOfOnlyOneAccount } = useCurrentUserInfo();
  const ts = useTranslate();

  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(
    isAdminOfOnlyOneAccount ? availableAccounts[0] : currentAccount
  );
  const [selectedAccountCode, setSelectedAccountCode] = useState<string>(
    currentAccount ? currentAccount.code : ''
  );

  const { onError } = useNotification();

  const { data: languages, loading: loadingLanguage } = useQuery<
    GetLanguagesData
  >(GetLanguages);

  const [
    getData,
    { data: selectorOptions, loading: loadingSelector }
  ] = useLazyQuery<GetAccountsData>(GetAccounts, {
    variables: { editableAccountsOnly: true, page: { first: 10, offset: 0 } }
  });

  let selectorList: IAccount[] = [];
  if (selectorOptions) {
    selectorList = selectorOptions.accounts.edges.map(({ node }) => node);
  }

  const handleSearch = (value: string) => {
    getData({ variables: { filter: value } });
  };

  const [
    invitePersons,
    { data: invitedPersons, loading: loadingInvitation }
  ] = useMutation<IUserAccountInvitationData>(InvitePersonsToAccount);

  const onSubmit = ({ emails, account, language }: TUsersCreation) => {
    const regexCode = /^(\S+)/;
    const selectedAccountCode = (regexCode.exec(
      account
    ) as RegExpMatchArray)[0];

    invitePersons({
      variables: { accountCode: selectedAccountCode, emails, language },
      onError: (e: ApolloError) => {
        onError(setErrorMessage(e, 'HC0045', ts('error.type.HC0045A'), 'X'));
      },
      refetchQueries: ['GetPersonsWithAccount']
    });
  };

  if (loadingInvitation || loadingLanguage)
    return <FormUsersCreationSkeleton />;

  if (invitedPersons) {
    const invitedPersonsArray = invitedPersons.invitePersonsToAccount;
    const invitationErrors = invitedPersonsArray.filter(inv => !!inv.error);
    const invitationsDelivered =
      invitedPersonsArray.length - invitationErrors.length;

    return (
      <FormUsersCreationSuccess
        failuredInvitations={invitationErrors}
        invitationsQuantity={invitationsDelivered}
        onClose={props.onCancel}
      />
    );
  }

  const languagesList =
    languages?.languages.edges.map(({ node }) => node) ?? [];

  return (
    <FormUsersCreation
      {...props}
      onSubmit={(usersCreation: TUsersCreation) =>
        onSubmit({
          emails: usersCreation.emails,
          account: selectedAccount?.code ?? '',
          language: usersCreation.language
        })
      }
      languages={languagesList}
      loadingData={loadingSelector}
      loading={loadingInvitation}
      selectorList={selectorList}
      onSearch={handleSearch}
      selectedAccount={selectedAccount}
      selectedAccountCode={selectedAccountCode}
      onChooseAccount={(acc: IAccount | null) => {
        setSelectedAccount(acc);
        setSelectedAccountCode(acc?.code ?? '');
      }}
    />
  );
};

export default FormUsersCreationContainer;
