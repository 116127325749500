import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import useTranslate from 'Hooks/useTranslate';
import testIds from 'Tests/TestIds';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { CdsSizeSpecificXxs, CdsSizeSpecificS } from '@cegid/design-tokens';
import { userFullName } from 'Tools';
import { PersonAvatar } from 'Theme/components/Avatar';
import { DataRow } from 'Theme/components/DataRow';
import { Link, generatePath, useParams } from 'react-router-dom';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import ApiKeyRevokeConfirmation from 'Page/ApiKeys/ApiKeyRevokeConfirmation';
import { PrivateRoutes } from 'Router/Routes';
import { useStyles } from './ApiKeyProfileGeneral.styles';
import { useAvatarColor } from 'Hooks/useAvatarColor';
import { FormattedDate, FormattedTime } from 'react-intl';

interface Props {
  apiKey: IApiKey;
}

const ApiKeyProfileGeneral = ({ apiKey }: Props) => {
  const ts = useTranslate();
  const { accountCode, apikeyUuid } = useParams<IUriParams>();
  const classes = useStyles();

  const apiColor = useAvatarColor({identifier: apiKey.uuid});

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item style={{ margin: CdsSizeSpecificXxs }}>
          <Avatar className={classes.apiAvatar} sx={{ background: apiColor }}>
            <VpnKeyIcon height={60} fontSize="inherit" />
          </Avatar>
        </Grid>
        <Grid
          item
          style={{
            margin: `${CdsSizeSpecificXxs} ${CdsSizeSpecificXxs} ${CdsSizeSpecificS}`
          }}
        >
          <Typography
            variant="h5"
            data-testid={testIds.pages.apiKeyProfile.infoTitle}
          >
            {apiKey.name}
          </Typography>
        </Grid>
      </Grid>
      <Paper className={classes.coloredBlockInfo} sx={{ background: apiColor }}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item xs={12} md={8}>
            <Typography
              variant="h5"
              paragraph
              color="inherit"
              className={classes.infoTitle}
            >
              {ts('page.apiKeyProfile.info.title')} {apiKey.name}
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              className={classes.infoSubtitle}
            >
              {ts('page.apiKeyProfile.info.subtitle', { value: apiKey.uuid })}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            {apiKey.state === ApiKeyState.Active && (
              <ApiKeyRevokeConfirmation
                button={true}
                api={apiKey}
                refetchQueries={() => {}}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <Box p={2} />
      <Card>
        <CardContent key={'CardContent' + 1}>
          <Typography variant="h5" paragraph color="inherit">
            {ts('page.apiKeyProfile.relatedServices')}
          </Typography>
          {!apiKey.accessRights ? (
            <Typography variant="body2" data-testid="noServices">
              {ts('page.apiKeyProfile.noRelatedServices')}
            </Typography>
          ) : (
            apiKey.accessRights.map((s: ILightServiceSubscription, i: any) => (
              <div
                key={'div' + s.label + '-' + s.groupCode + '-' + s.accountName}
              >
                <DataRow
                  key={'service-' + s.groupCode + '-' + s.label}
                  label={ts('page.apiKeyProfile.service')}
                  // divider={i !== apiKey.accessRights.length - 1}
                  divider
                >
                  <Typography variant="body2">
                    {s.label} ({s.groupCode})
                  </Typography>
                </DataRow>
                {!!apiKey.accessRights && (
                  <DataRow
                    key={'tiers-' + s.accountCode}
                    label={ts('page.apiKeyProfile.customerAccount')}
                    divider={i !== apiKey.accessRights.length - 1}
                  >
                    <Typography variant="body2">{s.accountName}</Typography>
                  </DataRow>
                )}
              </div>
            ))
          )}
        </CardContent>
      </Card>
      <Box p={2} />
      <Card>
        <CardContent key={'CardContent' + 2}>
          <Typography variant="h5" paragraph color="inherit">
            {ts('page.apiKeyProfile.contact')}
          </Typography>
          <DataRow
            key={'DataRow-creator'}
            label={ts('page.apiKeyProfile.creator')}
          >
            {apiKey.creator ? (
              <Link
                to={generatePath(PrivateRoutes.usersProfile.path, {
                  accountCode: accountCode,
                  immutableId: apiKey.creator.immutableId
                })}
                className={classes.creatorLink}
              >
                <ListItemAvatar>
                  <PersonAvatar component="span" person={apiKey.creator} />
                </ListItemAvatar>
                <ListItemText
                  primary={userFullName(
                    apiKey.creator?.firstName,
                    apiKey.creator?.lastName
                  )}
                  secondary={apiKey.creator?.email}
                />
              </Link>
            ) : (
              <Typography variant="body2">
                {ts('page.apiKeys.undefinedCreator')}
              </Typography>
            )}
          </DataRow>
        </CardContent>
      </Card>
      <Box p={2} />
      <Card>
        <CardContent key={'CardContent' + 4}>
          <Typography variant="h5" paragraph color="inherit">
            {ts('page.apiKeyProfile.technicalInformations')}
          </Typography>
          <DataRow key={'DataRow-uuid'} label={'ID'} divider>
            <Typography variant="body2">{apiKey.uuid}</Typography>
          </DataRow>
          <DataRow
            key={'DataRow-status'}
            label={ts('common.terminology.status')}
          >
            <Typography variant="body2">{apiKey.state}</Typography>
          </DataRow>
        </CardContent>
      </Card>
      <Box p={2} />
      <Card>
        <CardContent key={'CardContent' + 5}>
          <Typography variant="h5" paragraph color="inherit">
            {ts('page.apiKeyProfile.activities')}
          </Typography>
          {apiKey.revocationDate !== null && (
            <DataRow
              key={'DataRow-revocation'}
              label={ts('page.apiKeyProfile.revocationDate')}
              divider
            >
              <Typography variant="body2">
                <FormattedDate value={apiKey.revocationDate} /> -{' '}
                <FormattedTime value={apiKey.revocationDate} />
              </Typography>
            </DataRow>
          )}
          <DataRow
            key={'DataRow-creationDate'}
            label={ts('page.apiKeyProfile.creationDate')}
          >
            <Typography variant="body2">
              {apiKey.creationDate !== null
                ? (
                  <>
                    <FormattedDate value={apiKey.creationDate} /> -{' '}
                    <FormattedTime value={apiKey.creationDate} />
                  </> 
                )
                : ts('page.apiKeys.undefinedCreationDate')}
            </Typography>
          </DataRow>
          <Box className={classes.allActivitiesBlock}>
            <Button
              component={Link as any}
              color="primary"
              to={`/${accountCode}/apikey/${apikeyUuid}/audit`}
              variant="text"
            >
              {ts('page.userProfile.lastActivities.allActivities')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ApiKeyProfileGeneral;
